//Maybe create sync modal as seperate component for clarity if needed

import { useState } from 'react';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import DataSyncModal from './DataSyncModal';
import { Profilo } from '../pages/Profilo';
import { useNavigate } from 'react-router-dom';

export const UserProfile = () => {
	const [visible, setVisible] = useState(false);
	const [isSyncModal, setIsSyncModal] = useState(false);
	const [profilo,setProfilo] = useState(false)


	
	return (
		<>
		<Profilo open={profilo} closeButton={() => setProfilo(false)}/> 
			<div className='relative' onMouseLeave={() => setVisible(false)}>
			{sessionStorage.getItem("pic")!=="" && sessionStorage.getItem("pic")!=="null"?    <img
        alt="user 2"
        src={sessionStorage.getItem("pic")}
        className="h-8 w-8 dark:text-slate-200 text-miller-300 cursor-pointer rounded-full"
		onMouseOver={() => setVisible(true)}
    />:<HiOutlineUserCircle
					className='h-8 w-8 dark:text-slate-200 text-miller-300 cursor-pointer '
					onMouseOver={() => setVisible(true)}
				/>}
				<ul
					className={`absolute h-fit w-fit top-8 right-0 border-miller-300 border bg-white rounded-md py-2 text-sm flex-col z-[110] ${
						visible ? '' : 'hidden'
					}`}
				>
					<a onClick={() => setProfilo(!profilo)} className='flex items-start justify-center md:justify-start py-2 space-x-3 rounded-md  hover:bg-slate-200 cursor-pointer px-5'>
						Profilo
					</a>
					<a className='flex items-start justify-center md:justify-start py-2 space-x-3 rounded-md  hover:bg-slate-200 cursor-pointer px-5'>
						Impostazioni
					</a>
					<a
						className='flex items-start justify-center md:justify-start py-2 space-x-3 rounded-md  hover:bg-slate-200 cursor-pointer px-5'
						onClick={() => setIsSyncModal(true)}
					>
						Sincronizza dati
					</a>
					<a className='flex items-start justify-center md:justify-start py-2 space-x-3 rounded-md  hover:bg-slate-200 cursor-pointer px-5' href="/">
						Logout
					</a>
				</ul>
			</div>
			<DataSyncModal
				isOpen={isSyncModal}
				closeButton={() => setIsSyncModal(false)}
			/>
		</>
	);
};
