import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { Select } from "../../components/Select";
import { Input } from "../../components/Input";
import moment from "moment";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
  AreaChart,
  Area,
  Cell,
  LabelList,
} from "recharts";

export const Clienti = ({ screenSize, f24, dataF24 }) => {
  const [dataA, setDataA] = useState(moment(new Date()).format("YYYY-MM"));
  const [dataDa, setDataDa] = useState(
    moment(new Date()).subtract(6, "months").format("YYYY-MM")
  );
  const [parcellazione, setParcellazione] = useState([]);
  const [aziende, setAziende] = useState([]);
  const [aziendaSel, setAziendaSel] = useState(null);

  useEffect(() => {}, [screenSize]);

  const loadClienti = async (dataDa) => {
    const aziende = await api.get("/aziende");
    let aziende_filtered = aziende.data.filter((el) => {
      if (
        !el.data_fine_elaborazioni ||
        moment(el.data_fine_elaborazioni).isAfter(moment(dataDa))
      ) {
        return true;
      } else {
        return false;
      }
    });
    console.log(aziende_filtered);
    setAziende(aziende_filtered);
  };

  const loadParcellazione = async (da, a, az) => {
    const parc = await api.get(`/parcellazione/${az}`, {
      params: { da: da, a: a },
    });
    setParcellazione(parc.data);
  };

  useEffect(() => {
    //carico clienti subito
    loadClienti(dataDa, dataA);
    //
  }, []);

  useEffect(() => {
    if ((aziendaSel, dataDa, dataA)) {
      loadClienti(dataDa, dataA);
      loadParcellazione(
        moment(dataDa).format("YYYY-MM"),
        moment(dataA).format("YYYY-MM"),
        aziendaSel
      );
    }
  }, [aziendaSel, dataDa, dataA]); //se cliente sel, carico allora il grafico

  const handleChangeAzienda = async (e) => {
    setAziendaSel(e.target.value);
  };

  return (
    <div className="el-container h-fit ">
      <div className="grid grid-cols-4 gap-2 h-full">
        <div className="col-span-2 md:col-span-1">
          <Input
            type="month"
            label={"Da"}
            name="da"
            value={dataDa}
            onChange={async (e) => {
              await setDataDa(e.target.value);
              //loadParcellazione(moment(e.target.value).format("YYYY-MM"),moment(dataA).format("YYYY-MM"))
            }}
            max={moment(dataA).format("YYYY-MM")}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="month"
            label={"A"}
            name="a"
            onChange={async (e) => {
              await setDataA(e.target.value);
              //loadParcellazione(moment(dataDa).format("YYYY-MM"),moment(e.target.value).format("YYYY-MM"))
            }}
            min={moment(dataDa).format("YYYY-MM")}
            value={dataA}
          />
        </div>
        <div className="col-span-2 md:col-span-2">
          <Select
            type="text"
            label={"Azienda"}
            name="aziende"
            onChange={handleChangeAzienda}
            //value={cliente.tipologia_servizio_altro}
            placeholder="Seleziona..."
            options={aziende.map((el, index) => ({
              id: el.codice_univoco,
              name: el.ragione_sociale,
            }))}
            disabled={false}
          />
        </div>

        <div className="col-span-4 el-container h-80">
          <ResponsiveContainer width="100%" height="90%">
            <BarChart
              width={500}
              height={300}
              data={parcellazione}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="meseanno" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="cedolini_totali_mese"
                fill="#2265B1"
                activeBar={<Rectangle fill="#8dc4eb" stroke="#8dc4eb" />}
              >
                <LabelList
                  dataKey="cedolini_totali_mese"
                  position="insideBottom"
                  offset={25}
                  fill="black"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
