import { useState, useEffect } from "react";
import { TabPanel } from "../components/TabPanel";
import {
  MdBarChart,
  MdCheck,
  MdContactPhone,
  MdPayments,
} from "react-icons/md";
import { Button } from "../components/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { ConIncarico } from "./F24/ConIncarico";
import { SenzaIncarico } from "./F24/SenzaIncarico";
import api from "../api/api.js";
import { toast } from "react-toastify";
import moment from "moment";
import { TbCircleLetterZ,TbCircleLetterB,TbCircleLetterI } from "react-icons/tb";
import { Zucchetti } from "./Parcellazione/Zucchetti.js";
import { Bpoint } from "./Parcellazione/Bpoint.js";
import { IIpay } from "./Parcellazione/IIpay.js";

export const Parcellazione = ({screenSize}) => {
const [today,setToday] = useState(moment(new Date()).format("YYYY-MM"))
const [aziendeZucchetti,setAziendeZucchetti] = useState([]);
const [aziendeIipay,setAziendeIipay] = useState([]);
const [aziendeBpoint,setAziendeBpoint] = useState([]);


  const [tab, setTab] = useState(1);
  const [tabs, setTabs] = useState([
    {
      icon: <TbCircleLetterZ size={30} className="tab-icon" />,
      text: "Zucchetti",
      id: 1,
    },
    {
      icon: <TbCircleLetterB size={30} className="tab-icon" />,
      text: "Bpoint",
      id: 2,
    },
    {
      icon: <TbCircleLetterI size={30} className="tab-icon" />,
      text: "IIpay",
      id: 3,
    },
  ]);



  const loadAziende = async () => {
    try {
      const az = await api.get(`/aziende/parcellazione`, { params: { data: today,username:sessionStorage.getItem("username") } });
      setAziendeZucchetti(az.data.zucch)  //1 zucchetti
      setAziendeBpoint(az.data.bpoint)  //2 bpoint
      setAziendeIipay(az.data.iipay)  //3 iipay




    } catch {
      toast.error("Errore durante il caricamento")
    }

  }



  let content =[
    { child: <Zucchetti screenSize={screenSize} aziende={aziendeZucchetti} data={today} loadAziende={loadAziende} />, id: 1 },
    { child: <Bpoint screenSize={screenSize} aziende={aziendeBpoint}   data={today} loadAziende={loadAziende} /> , id: 2 },
    { child: <IIpay screenSize={screenSize} aziende={aziendeIipay}  data={today} loadAziende={loadAziende} />, id: 3 },
  ];




  useEffect( () => {
    loadAziende();
  },[today]);
  
  













  return (
    <>
    
      <div className="mx-auto w-full page-container gap-4">
        <div className="el-container h-fit mb-4">
          <div className="w-full flex flex-col md:flex-nowrap flex-wrap items-center justify-center mb-2 rounded-md h-16">

            <div className="text-xl font-semibold text-center">Mese selezionato: <input type="month" id="start" name="start" value={today} onChange={async (e) => { await setToday(e.target.value); }} /></div>
          </div>
        </div>
        <TabPanel tab={tab} setTab={setTab} tabs={tabs} content={content} />


        {/*} <TabPanel tab={tab} setTab={setTab} tabs={tabs} content={content} />
    
       <div className="flex w-full min-h-fit justify-end">
        <Button
          className="success-button"
          text="salva"
          type="button"
          icon={<MdCheck className="button-icon" />}
          onClick={async () => {
            console.log("salva");
          }}
        />
      </div> */}
      </div>
    </>
  );
};
