import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { Select } from "../../components/Select";
import { Input } from "../../components/Input";
import moment from "moment";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
  AreaChart,
  Area,
  Cell,
  LabelList,
} from "recharts";
import { Button } from "../../components/Button";
import { HiOutlineSave } from "react-icons/hi";
import * as EXCELJS from "exceljs";

export const Globale = ({ screenSize, f24, dataF24 }) => {
  const [utenteSel, setUtenteSel] = useState(null);
  const [dataA, setDataA] = useState(moment(new Date()).format("YYYY-MM"));
  const [dataDa, setDataDa] = useState(
    moment(new Date()).subtract(6, "months").format("YYYY-MM")
  );
  const [parcellazione, setParcellazione] = useState([]);
  const [aziendaSel, setAziendaSel] = useState(null);
  const [dataReport, setDataReport] = useState([]);

  useEffect(() => {}, [screenSize]);

  useEffect(() => {
    if (dataA && dataDa) {
      loadParcellazione(dataDa, dataA);
    } else {
      setAziendaSel(null);
    }
  }, [utenteSel, dataA, dataDa, aziendaSel]);

  const loadParcellazione = async (da, a) => {
    const parc = await api.get(`/parcellazione/statistiche/globale`, {
      params: { da: da, a: a },
    });
    setParcellazione(parc.data);
  };

  const stampaReport = async () => {
    const parc = await api.get(`/parcellazione/statistiche/globale/report`, {
      params: { da: dataDa, a: dataA },
    });
    setDataReport(parc.data);
    let data = parc.data;
    let colonne = Object.keys(data[0]).map((c) => c);
    // Extract Data (create a workbook object from the table)
    var workbook = new EXCELJS.Workbook();

    // Process Data (add a new row)
    var worksheet = workbook.addWorksheet("Data");
    //worksheet.pageSetup.printArea = "A1:G20";
    worksheet.addRow(colonne);

    var output = data.map((obj) => Object.keys(obj).map((key) => obj[key]));
    worksheet.addRows(output);
    output.forEach((row, rowIndex) => {
      row.forEach((value, colIndex) => {
        // Ottieni l'oggetto cella
        var cell = worksheet.getCell(rowIndex + 2, colIndex + 1); // Considera che stiamo partendo dalla riga 2 (dopo l'intestazione)

        // Definisci i criteri per il colore
        if (value == 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" }, // Rosso
          };
        } else if (value === "-") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF808080" }, // Verde
          };
        }
      });
    });
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "report.xlsx";
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="el-container h-fit ">
      <div className="grid grid-cols-4 gap-2 h-full">
        <div className="col-span-2 md:col-span-1">
          <Input
            type="month"
            label={"Da"}
            name="da"
            value={dataDa}
            onChange={async (e) => {
              await setDataDa(e.target.value);
              //loadParcellazione(moment(e.target.value).format("YYYY-MM"),moment(dataA).format("YYYY-MM"))
            }}
            max={moment(dataA).format("YYYY-MM")}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="month"
            label={"A"}
            name="a"
            onChange={async (e) => {
              await setDataA(e.target.value);
              //loadParcellazione(moment(dataDa).format("YYYY-MM"),moment(e.target.value).format("YYYY-MM"))
            }}
            min={moment(dataDa).format("YYYY-MM")}
            value={dataA}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Button
            className="success-button mt-6"
            text="Report"
            type="button"
            icon={<HiOutlineSave className="button-icon" />}
            onClick={async () => {
              stampaReport();
            }}
          />
        </div>

        <div className="col-span-4 el-container h-80 w-full">
          <ResponsiveContainer width="100%" height="90%">
            <BarChart
              width={500}
              height={300}
              data={parcellazione}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="nome" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={"totale_cedolini"}
                fill="#2265B1"
                activeBar={<Rectangle fill="#8dc4eb" stroke="#8dc4eb" />}
              >
                <LabelList
                  dataKey="totale_cedolini"
                  position="insideBottom"
                  offset={25}
                  fill="black"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="col-span-4 el-container h-80">
          <table id="my-table" className="hidden">
            <tr>
              {dataReport.length > 0
                ? Object.keys(dataReport[0])?.map((c, index) => (
                    <th key={index}>{c}</th>
                  ))
                : ""}
            </tr>
            {dataReport?.map((r, index) => (
              <tr key={index}>
                {Object.keys(r).map((o, index) => (
                  <td>{r[o]}</td>
                ))}
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};
