import React from "react";
import { HiLockClosed } from "react-icons/hi2";

export const Select = ({
  label,
  placeholder,
  type,
  onChange,
  value,
  name,
  disabled,
  options,
  className,
}) => {
  return (
    <div className="relative mb-2">
      <label className="text-gray-500 text-xs font-medium uppercase tracking-wider px-1 left-2 -top-2">
        {label} {disabled ? <HiLockClosed className="inline" /> : ""}
      </label>
      <select
        onChange={onChange}
        selected
        disabled={disabled}
        className={
          className +
          ` rounded-md p-2 pl-2 w-full focus:outline-success-200 ${
            disabled
              ? "border-2 border-primary-300 active:border-primary-300 focus-visible::border-primary-300 focus:border-primary-300 bg-gray-100"
              : "border border-gray-300 focus:border-gray-300"
          }`
        }
      >
        <option value={0}>{placeholder}</option>
        {options.map((el) => (
          <option
            key={el.id}
            value={el.id}
            name={name}
            selected={el.id === value ? "selected" : ""}
          >
            {el.name}
          </option>
        ))}
      </select>
    </div>
  );
};

/* 
<Select
  label={"Motivo fine servizio"}
  placeholder="Seleziona..."
  options={[{ id: 1, name: "test 1" },{ id: 2, name: "test 2" },]}
  disabled={true}
/>
*/
