import { useEffect, useState, useContext } from "react";
import { MdIncompleteCircle } from "react-icons/md";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";
import { CustomFooterPersonalized } from "../../components/DataGrid/CustomFooterPersonalized";
import { PrincipalButton } from "../../components/PrincipalButton";
import { Button } from "../../components/Button";
import { HiArrowDownTray, HiOutlineCheck } from "react-icons/hi2";
import api from "../../api/api";
import { toast } from "react-toastify";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { HiArrowDown, HiDownload } from "react-icons/hi";
import { createPortal } from "react-dom";
import Modal from "../../components/Modal";
import { CustomCheckbox } from "../../components/Checkbox";
import ExportCliente from "./ExportCliente";
import { knowAcces } from "../../utils/utils";

export const DashboardClienti = ({
  cliente,
  handleChange,
  handleSave,
  handleChangeSelect,
}) => {
  const [dataA, setDataA] = useState(moment(new Date()).format("YYYY-MM"));
  const [dataDa, setDataDa] = useState(
    moment(new Date()).subtract(12, "months").format("YYYY-MM")
  );
  const [dashboard, setDashboard] = useState();
  const [referenti, setReferenti] = useState([]);
  const [tipologie, setTipologie] = useState([]);
  const [parcellazione, setParcellazione] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <Button
            className="principal-button z-[110]"
            text={"Esportazione massiva"}
            type="button"
            icon={<HiDownload className="button-icon" />}
            onClick={handleExportReferenti}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "descrizione",
      headerName: "nominativo",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "data_inizio_minima",
      headerName: "Data inizio incarico",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        moment(params.row.data_inizio_minima).format("DD/MM/YYYY"),
      valueGetter: (params) =>
        moment(params.row.data_inizio_minima).format("DD/MM/YYYY"),
    },

    {
      field: "data_fine_massima",
      headerName: "Data fine incarico",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        moment(params.row.data_fine_massima).format("DD/MM/YYYY"),
      valueGetter: (params) =>
        moment(params.row.data_fine_massima).format("DD/MM/YYYY"),
    },
  ];

  const loadReferenti = async () => {
    try {
      const aziende = await api.get(
        `/referenti_aziende_interno/${cliente.codice_univoco}`
      );
      setReferenti(aziende.data);

      const tipologie = await api.get(`/tipologia_fine_servizio`);
      setTipologie(tipologie.data);
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };

  const loadParcellazione = async (da, a) => {
    const parc = await api.get(`/parcellazione/${cliente.codice_univoco}`, {
      params: { da: da, a: a },
    });
    setParcellazione(parc.data);
  };

  useEffect(() => {
    loadReferenti();
    loadParcellazione(
      moment(new Date()).subtract(12, "months").format("YYYY-MM"),
      moment(new Date()).format("YYYY-MM")
    );
  }, []);

  const handleExportReferenti = async () => {
    try {
      let response = await api.post(
        `/exports/referenti_interno_azienda`,
        {},
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "" })
      ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "referenti.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.success("donwnload eseguito con successo");
    } catch {
      toast.error("errore nel download dei documenti");
    }
  };

  return (
    <>
      {openModal &&
        createPortal(
          <ExportCliente
            setOpenModal={setOpenModal}
            codice_azienda={cliente.codice_univoco}
          />,
          document.getElementById("portalModal")
        )}

      <PrincipalButton
        button={
          <div className="flex flex-row gap-2">
            <Button
              className="principal-button z-[110]"
              text="Esporta"
              type="button"
              icon={<HiArrowDownTray className="button-icon" />}
              onClick={async () => {
                setOpenModal(true);
              }}
            />
            <Button
              className="principal-button z-[110]"
              text="Salva"
              type="button"
              icon={<HiOutlineCheck className="button-icon" />}
              onClick={async () => {
                handleSave();
              }}
            />
          </div>
        }
      />
      <div className="grid grid-cols-2 gap-3 w-full">
        <div className="col-span-2 el-container h-80">
          <div className="flex flex-row gap-2">
            {" "}
            Grafico cedolini
            <Input
              type="month"
              label={"Da"}
              name="da"
              value={dataDa}
              onChange={async (e) => {
                await setDataDa(e.target.value);
                loadParcellazione(
                  moment(e.target.value).format("YYYY-MM"),
                  moment(dataA).format("YYYY-MM")
                );
              }}
              max={moment(dataA).format("YYYY-MM")}
            />
            <Input
              type="month"
              label={"A"}
              name="a"
              onChange={async (e) => {
                await setDataA(e.target.value);
                loadParcellazione(
                  moment(dataDa).format("YYYY-MM"),
                  moment(e.target.value).format("YYYY-MM")
                );
              }}
              min={moment(dataDa).format("YYYY-MM")}
              value={dataA}
            />
          </div>

          <ResponsiveContainer width="100%" height="90%">
            <BarChart
              width={500}
              height={300}
              data={parcellazione}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="meseanno" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="cedolini_totali_mese"
                fill="#2265B1"
                activeBar={<Rectangle fill="#8dc4eb" stroke="#8dc4eb" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="col-span-2 el-container ">
          <div className="grid grid-cols-2">
            <div>Storico Referenti</div>
          </div>

          <div className="h-64">
            <DataGridPro
              density="compact"
              slots={{
                toolbar: CustomToolbar,
                loadingOverlay: LinearProgress,
                footer: CustomFooterPersonalized,
              }}
              //loading={loading}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              columns={columns}
              rows={referenti}
              getRowId={(r) => r.id}
            />
          </div>
        </div>

        <div className="col-span-2 grid grid-cols-2 gap-2 el-container">
          <div className="col-span-2 md:col-span-1">
            <Input
              type="date"
              label={"data inizio elaborazioni"}
              name="data_inizio_elaborazioni"
              onChange={(e) => handleChange(e)}
              value={
                cliente.data_inizio_elaborazioni
                  ? moment(cliente.data_inizio_elaborazioni).format(
                      "YYYY-MM-DD"
                    )
                  : null
              }
              disabled={!knowAcces(["admin", "team_leader"])}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              type="date"
              label={"data cessazione attività"}
              name="data_cessazione"
              onChange={(e) => handleChange(e)}
              value={
                cliente.data_cessazione
                  ? moment(cliente.data_cessazione).format("YYYY-MM-DD")
                  : null
              }
              disabled={!knowAcces(["admin", "team_leader"])}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              type="date"
              label={"data fine elaborazioni"}
              name="data_fine_elaborazioni"
              onChange={(e) => handleChange(e)}
              value={
                cliente.data_fine_elaborazioni
                  ? moment(cliente.data_fine_elaborazioni).format("YYYY-MM-DD")
                  : null
              }
              disabled={!knowAcces(["admin", "team_leader"])}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Select
              label={"Motivo fine servizio"}
              placeholder="Seleziona..."
              options={tipologie}
              value={cliente.motivo_fine_servizio}
              onChange={(e) => handleChangeSelect(e, "motivo_fine_servizio")}
              disabled={!knowAcces(["admin", "team_leader"])}
            />
          </div>
        </div>
      </div>
    </>
  );
};

/*
 <div className="flex flex-row w-full">
                {
                  el.campi.map((campo, index_campo) => {
                    return (



                      <CustomCheckbox
                        label={campo?.name}
                        name={campo?.column_name}
                        checked={campo?.includi}
                        onClick={(e) => handleChangeCheckbox(e, index, index_campo)}
                        //onClick={(e) => filter(e, "assegnati")}
                        value={campo?.includi}
                        disabled={false}
                      />

                    )


                  }

                  )
                }</div>

              <div>
              </div>
*/
