import { useState, useRef, useEffect } from 'react';
import { Button } from '../components/Button';
import api from '../api/api';
import EmailEditor from 'react-email-editor';
import {
	DataGridPro,
	itIT,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { HiArrowLeft, HiArrowRight, HiCheck, HiOutlineCheck, HiPlus } from 'react-icons/hi2';
import LinearProgress from '@mui/material/LinearProgress';
import { CustomFooterPersonalized } from '../components/DataGrid/CustomFooterPersonalized';
import { createPortal } from 'react-dom';
import Modal from '../components/Modal';
import { HiMail, HiOutlinePaperClip, HiOutlineX, HiSave, HiX } from 'react-icons/hi';
import { MdCopyAll, MdDelete, MdEdit } from 'react-icons/md';
import { Input } from '../components/Input';
import { toast } from 'react-toastify';
import { Select as CustomSel } from '../components/Select';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import MultiSelectComponent from '../components/MultiSelect';
import Select from 'react-select';

const MAX_ATTACHMENT_SIZE = 25 * 1024 * 1024; 

export const Newsletter = ({ screenSize }) => {
	const [openModal, setOpenModal] = useState(false);
	const [openModalError, setOpenModalError] = useState(false);
	const [openModalCopy, setOpenModalCopy] = useState(false);
	const [newsletter, setNewsletter] = useState([])
	const [operazione, setOperazione] = useState("inserimento");
	const [newsletterSel, setNewsletterSel] = useState(null);
	const [titolo, setTitolo] = useState("");
	const [liste, setListe] = useState("");
	const [procedi, setProcedi] = useState(false);
	const [conferma, setConferma] = useState(false);
	const [htmlMail, setHtmlMail] = useState(null);
	const [tipologiaInvio, setTipologiaInvio] = useState("1");
	const [ccnl, setCcnl] = useState([]);
	const emailEditorRef = useRef(null);
	const [cluster, setCluster] = useState(null);
	const [loading, setLoading] = useState(false);
	const [idSel, setIdSel] = useState(null)
	const [allegati, setAllegati] = useState([]);

	const columns = [
		{
			field: 'Azioni',
			headerName: 'Azioni',
			width: screenSize > '768' ? 150 : 70,
			headerAlign: 'center',
			align: 'center',
			filterable: false,
			renderCell: (params) => {
				return (
					<>
						<div className='hidden md:flex flex-nowrap '>
							<Button
								className='edit-button'
								type='button'
								icon={<MdEdit className='button-icon' />}
								onClick={async () => { setIdSel(params.row.id); setConferma(false); setProcedi(false); setNewsletterSel(params.row); setTitolo(params.row.titolo); setOperazione("modifica"); setOpenModal(true) }}
							/>

							<Button
								className='edit-button'
								type='button'
								icon={<MdCopyAll className='button-icon' />}
								onClick={async () => { setNewsletterSel(params.row); setOpenModalCopy(true) }}
							/>
							<Button
								className='edit-button'
								type='button'
								icon={<MdDelete className='button-icon text-red-500' />}
								onClick={async () => { setNewsletterSel(params.row); setOpenModalError(true) }}
							/>
						</div>

					</>
				);
			},
		},
		{
			field: 'titolo',
			headerName: 'Titolo',
			width: 150,
			flex: screenSize > '768' ? 1 : 0,
			headerAlign: 'center',
			align: 'center',
		},
		{
			field: 'data_invio',
			headerName: 'Data invio',
			width: 150,
			flex: screenSize > '768' ? 1 : 0,
			headerAlign: 'center',
			align: 'center',
			renderCell: (params) => params.value ? moment(params.value).format("DD/MM/YYYY") : ""
		},

	];




	const handleProcedi = async () => {
		setLoading(false)
		emailEditorRef.current.editor.saveDesign(async (design) => {
			if (operazione === "inserimento") {


				let resIns = await api.post("/newsletter", { ns: { contenuto: JSON.stringify(design), titolo: titolo } })
				setIdSel(resIns.data.insertId)
			} else {
				let resUp = await api.put("/newsletter", { ns: { contenuto: JSON.stringify(design), titolo: titolo, id: newsletterSel.id } })

			}
		});
		loadNewsletter();

		emailEditorRef.current.editor.exportHtml(async (data) => {
			const { design, html } = data;

			setHtmlMail(html)
			setTipologiaInvio("1");
			setProcedi(true)
			setConferma(false);

		});



	}


	const loadNewsletter = async () => {
		const aziende = await api.get("/newsletter");
		setNewsletter(aziende.data)
	}
	useEffect(() => {
		loadNewsletter();
		loadListe();
		loadCcnl();
	}, []);



	const loadListe = async () => {

		const rispListe = await api.get(`/liste`);
		setListe(rispListe.data)
	}

	const loadCcnl = async () => {

		const rispListe = await api.get(`/ccnl/newsletter`);
		setCcnl(rispListe.data)
	}



	const exportSave = async () => {
		try {
			emailEditorRef.current.editor.saveDesign(async (design) => {
				if (operazione === "inserimento") {


					await api.post("/newsletter", { ns: { contenuto: JSON.stringify(design), titolo: titolo } })
				} else {
					await api.put("/newsletter", { ns: { contenuto: JSON.stringify(design), titolo: titolo, id: newsletterSel.id } })
				}
			}

			)
			toast.success("Salvataggio avvenuto con successo")
		} catch {
			toast.error("Errore durante il salvataggio")
		}

	};





	const sendTest = async () => {
		try {
			

			if(htmlMail){
				await api.post('/newsletter/test', { mail: "federico.barenghi@millergroup.it", titolo: titolo, contenuto: htmlMail,attachments:allegati })
			}else{
				emailEditorRef.current.editor.saveDesign(async (design) => {
					if (operazione === "inserimento") {
	
	
						await api.post("/newsletter", { ns: { contenuto: JSON.stringify(design), titolo: titolo } })
					} else {
						await api.put("/newsletter", { ns: { contenuto: JSON.stringify(design), titolo: titolo, id: newsletterSel.id } })
					}
				}
	
				)
				emailEditorRef.current.editor.exportHtml(async (data) => {
					const { design, html } = data;
	
					await api.post('/newsletter/test', { mail: "federico.barenghi@millergroup.it", titolo: titolo, contenuto: html })
	
	
	
				});

			}

		
			toast.success("Mail test inviata con successo");
		} catch {
			toast.error("Errore durante l'invio");
		}
	}

	const aggiornaDb = async () => {
		const result = await api.get('/t');
		console.log(result);
	};


	const onLoad = () => {
		// editor instance is created
		// you can load your template here;
		// const templateJson = {};
		// emailEditorRef.current.editor.loadDesign(templateJson);

		if (operazione === "modifica") {


			emailEditorRef.current.editor.loadDesign(JSON.parse(newsletterSel.contenuto));
		}

	}



	const sendToCluster = async () => {
		try {
			setLoading(true)
			await api.post('/newsletter/send', { tipologia: tipologiaInvio, cluster: cluster, titolo: titolo, contenuto: htmlMail, idSel: idSel })
			setLoading(false)
			loadNewsletter();
			toast.success("Newsletter inviata con successo")
		} catch {
			setLoading(false)
			toast.error("Errore durante l'invio della newsletter")
		}



	}





	const deleteNewsletter = async () => {
		try {




			await api.delete(`/newsletter/${newsletterSel.id}`)

			loadNewsletter();
			setOpenModalError(false)
			toast.success("Eliminato con successo")

		} catch {
			toast.error("Errore durante il salvataggio")
		}

	};

	const handleFileChange = (e) => {
		const files = e.target.files;
		const selectedFiles = Array.from(e.target.files);
		const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
	
		if (totalSize > MAX_ATTACHMENT_SIZE) {
		  toast.error('Files troppi grandi');
		  return;
		} 
		if (files.length > 0) {
			Array.from(files).forEach((file) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);

				reader.onload = () => {
					const base64String = reader.result;
					console.log(base64String)
					// Update the state by adding the new document with name and data to the array if it doesn't exist already
					setAllegati((prevAllegati) => {
						// Check if the file is already in the allegati array
						const fileExists = prevAllegati.some(allegato => allegato.name === file.name);

						// If the file doesn't exist, add it to the array
						if (!fileExists) {
							return [
								...prevAllegati,
								{
									name: file.name,
									data: base64String.split(',')[1],
								},
							];
						}

						// If the file exists, return the previous state without changes
						return prevAllegati;
					});
				};

				reader.onerror = (error) => {
					console.error("Error reading the file:", error);
				};
			});
		}
	};


	const handleRemove = () => {

		setAllegati([]);


	}




	const handleAttach = () => {

		document.getElementById("uploadDoc").click()

	}


	const handleChange = (selected) => {
		setCluster(selected);

	};



	const copyNewsletter = async () => {
		try {



			await api.post("/newsletter", { ns: { contenuto: newsletterSel.contenuto, titolo: "COPIA - " + newsletterSel.titolo } })



			loadNewsletter();
			setOpenModalCopy(false)
			toast.success("Copiato con successo")
		} catch {
			toast.error("Errore durante il salvataggio")
		}

	};




	const onReady = () => {
		// editor is ready
		console.log('onReady');
	};
	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<div className='w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1'>
					<div className='flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2'>
						<GridToolbarQuickFilter placeholder='Cerca...' />
					</div>
					<div className='flex justify-center md:justify-end md:mx-0 mx-auto'>
						<Button
							className='success-button'
							size='md'
							text={'Nuovo'}
							icon={<HiPlus className='button-icon' />}
							onClick={() => { setProcedi(false); setOperazione("inserimento"); setTitolo(""); setOpenModal(true) }}
						/>
					</div>
				</div>
			</GridToolbarContainer>
		);
	}

	return (
		<>

			{openModalCopy && createPortal(
				<Modal isOpen={openModalCopy} closeButton={() => setOpenModalCopy(false)} leftButtons={

					<Button
						className="principal-button z-[110]"
						text={"Salva"}
						type="button"
						icon={<HiOutlineCheck className="button-icon" />}
						onClick={async () => {
							copyNewsletter()
						}}
					/>
				}>
					Sei sicuro di voler copiare la newsletter selezionata?
				</Modal>,
				document.getElementById("portalModal")
			)}
			{openModalError && createPortal(
				<Modal isOpen={openModalError} closeButton={() => setOpenModalError(false)} headerText={"Elimina newsletter"} leftButtons={

					<Button
						className="principal-button z-[110]"
						text={"Elimina"}
						type="button"
						icon={<HiOutlineCheck className="button-icon" />}
						onClick={async () => {
							deleteNewsletter()
						}}
					/>
				}>
					Sei sicuro di voler eliminare la newsletter selezionata?
				</Modal>,
				document.getElementById("portalModal")
			)}

			{openModal && createPortal(
				<Modal isOpen={openModal} closeButton={() => setOpenModal(false)} >

					<div className=" md:h-fit">

						<div className='w-full flex z-50 relative flex-col  gap-2'>
							<div className='flex flex-row justify-between'><div className=''>  <Input
								type="text"
								label={"Oggetto"}
								name=""
								onChange={(e) => setTitolo(e.target.value)}
								value={titolo}
								disabled={false}
							/></div>

								<div className='flex  gap-2'>
									{!procedi ? <>


										<Button
											className='success-button'
											size='md'
											text={'Test'}
											icon={<HiMail className='button-icon' />}
											onClick={sendTest}
										/>

										<Button
											className='success-button'
											size='md'
											text={'Salva'}
											icon={<HiSave className='button-icon' />}
											onClick={exportSave}
										/>
										<Button
											className='success-button'
											size='md'
											text={'Procedi'}
											icon={<HiArrowRight className='button-icon' />}
											onClick={handleProcedi}
										/>
									</> :
										<>
											{cluster && cluster != 0 ? <Button
												className='success-button'
												size='md'
												text={conferma ? 'Annulla' : 'Conferma e invia'}
												icon={conferma ? <HiX /> : <HiMail className='button-icon' />}
												onClick={() => setConferma(!conferma)}
											/> : ""}
											<Button
											className='success-button'
											size='md'
											text={'Test'}
											icon={<HiMail className='button-icon' />}
											onClick={sendTest}
										/>
											<Button
												className='success-button'
												size='md'
												text={'Allega'}
												icon={<HiOutlinePaperClip className='button-icon' />}
												onClick={handleAttach}
											/>
											<Button
												className='success-button'
												size='md'
												text={'Indietro'}
												icon={<HiArrowLeft className='button-icon' />}
												onClick={() => setProcedi(false)}
											/>

										</>

									}
								</div>
							</div>


							{procedi ?

								<>
									<input
										id="uploadDoc"
										type="file"
										//accept="application/pdf"
										className="hidden"
										onChange={handleFileChange}
										multiple
									/>
									<div className='w-full h-96 grid grid-cols-2 gap-2'>
										<div className='flex flex-col gap-2'>

											<div dangerouslySetInnerHTML={{ __html: htmlMail }} />
											{allegati.length > 0 &&
												<div className='flex flex-row gap-2'>
													<Input
														type="text"
														label={"Allegati"}
														name=""
														value={allegati.map(el => el.name).join(" - ")}
														disabled={true}
													/>
													<div>
														<p className='invisible'>PINO</p>
														<Button
															className='error-button'
															size='md'
															text={'Rimuovi allegati'}
															icon={<HiOutlineX className='button-icon' />}
															onClick={handleRemove}
														/>
													</div>
												</div>

											}
										</div>
										<div className='flex flex-col gap-2'>
											<div className='flex flex-row justify-center gap-4'>
												<div>
													<input type="radio" id="tipologia_invio" name="tipologia_invio" value="1" checked={tipologiaInvio == 1} defaultValue={tipologiaInvio} onClick={(e) => { setConferma(false); setCluster(null); setTipologiaInvio(e.target.value) }} />
													<label for="dewey">Lista</label>
												</div>
												<div>
													<input type="radio" id="tipologia_invio" name="tipologia_invio" value="2" checked={tipologiaInvio == 2} defaultValue={tipologiaInvio} onClick={(e) => { setConferma(false); setCluster(null); setTipologiaInvio(e.target.value) }} />
													<label for="dewey">CCNL</label>
												</div>
												<div>
													<input type="radio" id="tipologia_invio" name="tipologia_invio" value="3" checked={tipologiaInvio == 3} defaultValue={tipologiaInvio} onClick={(e) => { setConferma(false); setCluster(true); setTipologiaInvio(e.target.value) }} />
													<label for="dewey">interna</label>
												</div>
											</div>
											<div>

												{tipologiaInvio == 1 ?
													<>
														<Select
															isMulti
															value={cluster}
															onChange={handleChange}
															options={liste.map((el) => { return { value: el.id, label: el.name } })}
														/>


													</>



													: ""}
												{tipologiaInvio == 2 ? <Select
													isMulti
													value={cluster}
													onChange={handleChange}
													options={ccnl.map((el) => { return { value: el.id, label: el.name } })}
												/> : ""}

												{conferma &&
													<div className='flex flex-col justify-center items-center gap-4'>
														{loading ? <div>

															<CircularProgress />

														</div> :
															<>
																<div className='font-bold'>ATTENZIONE,  SEI SICURO DI INVIARE LA MAIL AL CLUSTER SELEZIONATO?</div>
																<div>
																	<Button
																		className='success-button'
																		size='md'
																		text={'Invia'}
																		icon={<HiCheck className='button-icon' />}
																		onClick={() => { sendToCluster() }} />
																</div>


															</>
														}
													</div>
												}

											</div>

										</div>
									</div>
								</>

								: <EmailEditor

									ref={emailEditorRef}
									onLoad={onLoad}
									onReady={onReady}
								/>}
						</div>
					</div>

				</Modal>,
				document.getElementById("portalModal")
			)}

			<div className='page-container'>


				<div className='w-full md:h-[70vh] h-[70vh] relative el-container'>
					<DataGridPro
						density='compact'
						slots={{
							toolbar: CustomToolbar,
							loadingOverlay: LinearProgress,
							footer: CustomFooterPersonalized,
						}}
						//loading={loading}
						localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
						columns={columns}
						rows={newsletter}
						getRowId={(r) => r.id}
					/>
				</div>
			</div>
		</>
	);
};





/*


  <div className='w-full flex z-50 relative flex-col h-full page-container'>
	  <div>
		<button onClick={exportHtml}>Export HTML</button>
	  </div>
	  
	  <EmailEditor
		ref={emailEditorRef}
		onLoad={onLoad}
		onReady={onReady}
	  />
	</div>

*/





