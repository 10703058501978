import { useState, useEffect } from "react";
import { TabPanel } from "../components/TabPanel";
import {
  MdBarChart,
  MdCheck,
  MdContactPhone,
  MdContentPaste,
  MdPayments,
} from "react-icons/md";
import { Button } from "../components/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { ConIncarico } from "./F24/ConIncarico";
import { SenzaIncarico } from "./F24/SenzaIncarico";
import api from "../api/api.js";
import { toast } from "react-toastify";
import moment from "moment";
import { Cedolini } from "./Statistiche/Cedolini.js";
import { ReferentiPaghe } from "./Statistiche/ReferentiPaghe.js";
import { HiCalculator, HiCube, HiOutlineHomeModern } from "react-icons/hi2";
import { Clienti } from "./Statistiche/Clienti.js";
import {
  HiAnnotation,
  HiArchive,
  HiExternalLink,
  HiGlobe,
  HiMail,
  HiOutlineCurrencyEuro,
} from "react-icons/hi";
import { Globale } from "./Statistiche/Globale.js";
import { Parcellazione } from "./Anagrafiche/Parcellazione.js";
import { Liste } from "./Anagrafiche/Liste.js";
import { Accounting } from "./Anagrafiche/Accounting.js";
import { NewsletterExtraLista } from "./Anagrafiche/NewsletterExtraLista.js";
import { TipologiaServizioAssunzioni } from "./Anagrafiche/TipologiaServizioAssunzioni.js";
import { TipologiaF24 } from "./Anagrafiche/TipologiaF24.js";
import { CCNL } from "./Anagrafiche/CCNL.js";

export const Anagrafiche = ({ screenSize }) => {
  const [today, setToday] = useState(moment(new Date()).format("YYYY-MM"));
  const [giornof24, setGiornoF24] = useState(null);

  const [tab, setTab] = useState(1);
  const [tabs, setTabs] = useState([
    {
      icon: <HiOutlineCurrencyEuro size={30} className="tab-icon" />,
      text: "Prestazioni",
      id: 1,
    },
    {
      icon: <HiCalculator size={30} className="tab-icon" />,
      text: "Referenti Acounting",
      id: 2,
    },
    {
      icon: <HiMail size={30} className="tab-icon" />,
      text: "Liste",
      id: 3,
    },

    {
      icon: <HiExternalLink size={30} className="tab-icon" />,
      text: "Contatti extra newsletter ",
      id: 4,
    },

    {
      icon: <HiArchive size={30} className="tab-icon" />,
      text: "Tipologia servizio assunzioni",
      id: 5,
    },
    {
      icon: <HiAnnotation size={30} className="tab-icon" />,
      text: "Stati F24",
      id: 6,
    },
    {
      icon: <HiCube size={30} className="tab-icon" />,
      text: "Raccordi IPSOA",
      id: 7,
    },
  ]);
  let content = [
    { child: <Parcellazione screenSize={screenSize} />, id: 1 },
    { child: <Accounting screenSize={screenSize} />, id: 2 },
    { child: <Liste screenSize={screenSize} />, id: 3 },
    { child: <NewsletterExtraLista screenSize={screenSize} />, id: 4 },
    { child: <TipologiaServizioAssunzioni screenSize={screenSize} />, id: 5 },
    { child: <TipologiaF24 screenSize={screenSize} />, id: 6 },
    { child: <CCNL screenSize={screenSize} />, id: 7 },
  ];

  return (
    <>
      <div className="mx-auto w-full page-container gap-4 pt-4">
        <div className="el-container h-fit mb-4 invisible"></div>

        <TabPanel tab={tab} setTab={setTab} tabs={tabs} content={content} />

        {/* <div className="flex w-full min-h-fit justify-end">
        <Button
          className="success-button"
          text="salva"
          type="button"
          icon={<MdCheck className="button-icon" />}
          onClick={async () => {
            console.log("salva");
          }}
        />
      </div> */}
      </div>
    </>
  );
};
