import Modal from './Modal';
import { Button } from './Button';
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import api from '../api/api';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

//3 CASES
//1:Initial State : 0  => User requests update => inital state 1 => update ends => inital state 0
//2:Initial State : 1  => User requests update => warning message
//3:Initial State : 0  => Two or more separate users requests update => The fatser one arrives the other is ejected => update ends => inital state 0

const DataSyncModal = ({ isOpen, closeButton }) => {
	const [isUpdating, setisUpdating] = useState(false);

	useEffect(() => {
	
		checkState();
	}, [isOpen]);

	const checkState = async () => {
		const result = await api.get('sync');

		setisUpdating(result.data.state);
	};

	const handleSave = async () => {
		let toastConfig = {};
		setisUpdating(true);
		try {
			const result = await api.put('sync');
			const { message, status } = result.data;
			switch (status) {
				case 'success':
					toastConfig = { type: 'success', message };
					break;
				case 'info':
					toastConfig = { type: 'warn', message };
					break;
				case 'error':
					toastConfig = { type: 'error', message };
					break;
				default:
					toastConfig = { type: 'error', message: 'Errore generico' };
			}
			toast[toastConfig.type](toastConfig.message, {
				position: 'top-right',
				autoClose: 4000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
		} catch (error) {
			toast.error('Errore durante la sincronizzazione', {
				position: 'top-right',
				autoClose: 4000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
		} finally {
			setisUpdating(false);
		}
	};

	return (
		<>
			{!isUpdating ? (
				<Modal
					isOpen={isOpen}
					closeButton={closeButton}
					headerText='Sincronizzazione dei Dati'
					size={'small'}
					rightButtons={
						<Button
							className='success-button'
							size='md'
							text={'Conferma'}
							onClick={handleSave}
							icon={<AiOutlineCheck size={20} />}
						/>
					}
					leftButtons={
						<Button
							className='error-button'
							size='md'
							text={'Annulla'}
							onClick={closeButton}
							icon={<AiOutlineClose size={20} />}
						/>
					}
				>
					<div className='flex justify-center text-center items-center my-4'>
						Sei sicuro di voler inviare la richiesta di sincronizzazione di
						tutti i dati
					</div>
				</Modal>
			) : (
				<Modal
					isOpen={isOpen}
					closeButton={closeButton}
					headerText='Sincronizzazione dei Dati'
					size={'small'}
					rightButtons={
						<Button
							className='success-button-disabled'
							size='md'
							disabled={true}
							text={'Conferma'}
							onClick={handleSave}
							icon={<AiOutlineCheck size={20} />}
						/>
					}
					leftButtons={
						<Button
							className='error-button'
							size='md'
							text={'Esci'}
							onClick={closeButton}
							icon={<AiOutlineClose size={20} />}
						/>
					}
				>
					<div className='flex justify-center text-center items-center my-4'>
						Attenzione, la sincronizzazione dei dati è già in corso
					</div>
					<LinearProgress />
				</Modal>
			)}
		</>
	);
};

export default DataSyncModal;
