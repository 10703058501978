import { axiosPrivate } from '../api/api';

const useRefreshToken = () => {
	const refresh = async () => {
		const response = await axiosPrivate.get('/refresh', {});
		console.log('Refresh token hook response: ');
		console.log(response);
		const { accessToken } = response.data;
		window.sessionStorage.setItem('accessToken', accessToken);
		//window.sessionStorage.setItem('permission', permission);
		//window.sessionStorage.setItem('username', username);

		return accessToken;
	};
	return refresh;
};

export default useRefreshToken;
