//icone utilizzate OUTLINE https://react-icons.github.io/react-icons/icons?name=hi2
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Outlet, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import { Home } from "./pages/Home";
import Login from "./pages/Login";
import { AppLayout } from "./pages/AppLayout";
import { Clienti } from "./pages/Clienti";
import { F24 } from "./pages/F24";
import { Uniemens } from "./pages/Uniemens";
import { Parcellazione } from "./pages/Parcellazione";
import { Newsletter } from "./pages/Newsletter";

import { LicenseInfo } from "@mui/x-data-grid-pro";
import { DettaglioClienti } from "./pages/Clienti/DettaglioClienti";
import { DashboardClienti } from "./pages/Clienti/DashboardClienti";
import  RequireAuth  from "./components/RequireAuth";

import { TransitionProvider } from "./components/DataGrid/TransitionContext";
import TransitionComponent from "./pages/Transition";
import { Referenti } from "./pages/Referenti";
import { Statistiche } from "./pages/Statistiche";
import {SetPassword } from "./pages/SetPassword";
import { Anagrafiche } from "./pages/Anagrafiche";
LicenseInfo.setLicenseKey(
  "5b30fac79aff89e43e91021018cca43dTz04MTU1MyxFPTE3MzU5OTY0NDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
}, [screenSize]);

const router = createBrowserRouter(createRoutesFromElements(

    <>
      <Route path="/" element={<Login />} />
      <Route path="/setPassword" element={<SetPassword />} />
      <Route element={<RequireAuth />} >
        <Route path="/" element={<AppLayout />}>
          <Route path="/Home" element={<Home screenSize={screenSize} />} />
          <Route path="/Clienti" element={<Clienti screenSize={screenSize} />} />
          <Route path="/DettaglioClienti" element={<DettaglioClienti screenSize={screenSize} />} />
          <Route path="/F24" element={<F24 screenSize={screenSize} />} />
          <Route path="/Uniemens" element={<Uniemens screenSize={screenSize} />} />
          <Route path="/Parcellazione" element={<Parcellazione screenSize={screenSize} />} />
          <Route path="/Newsletter" element={<Newsletter screenSize={screenSize} />} />
          <Route path="/Referenti" element={<Referenti screenSize={screenSize} />} />
          <Route path="/Statistiche" element={<Statistiche screenSize={screenSize} />} />
          <Route path="/Anagrafiche" element={<Anagrafiche screenSize={screenSize} />} />
        </Route>
      </Route>
    </>
 
))


  return (
    <>
      <TransitionProvider>
  <RouterProvider router={router} />
  </TransitionProvider>
    </>
  );
}

export default App;

/*
const router = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/Clienti",
    element: <Clienti  />,
    loader:aziendeLoader
  },
  {
    path: "/Home",
    element: <Home  />,
  },
  {
    path: "/DettaglioClienti",
    element: <DettaglioClienti  />,
  },
  {
    path: "/F24",
    element: <F24 />,
  },
]

*/