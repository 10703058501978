import { useEffect, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { MdEdit, MdDelete, MdAdd, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import LinearProgress from '@mui/material/LinearProgress';
import {
    DataGridPro,
    itIT,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { Select } from '../../components/Select';
import { Input } from '../../components/Input';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment';
import { CustomFooterPersonalized } from '../../components/DataGrid/CustomFooterPersonalized';
import { SlOptionsVertical } from 'react-icons/sl';
import Modal from '../../components/Modal';
import {
    HiMiniArrowDownTray,
    HiOutlineCheck,
    HiOutlinePlus,
    HiOutlineTrash,
    HiOutlineXMark,
    HiPlus,
} from 'react-icons/hi2';
import { PrincipalButton } from '../../components/PrincipalButton';
import api from '../../api/api';
import { CustomCheckbox } from '../../components/Checkbox';




export const Accounting = ({ screenSize }) => {


    const [referenti, setReferenti] = useState([]);
    const [referenteSel, setReferenteSel] = useState(null);


    const columns = [
        {
            field: 'Azioni',
            headerName: 'Azioni',

            headerAlign: 'center',
            align: 'center',
            filterable: false,
            renderCell: (params) => {
                return (<Button
                    className='edit-button '
                    type='button'
                    icon={<MdEdit className='button-icon' />}
                    onClick={async () => {
                        setReferenteSel(params.row)

                    }
                    }
                />)

            }

        },
        {
            field: "name",
            headerName: "Nome",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },


    ];
    useEffect(() => { }, [screenSize]);

    const loadReferenti = async () => {
        const prest = await api.get("/referenti_accounting");
        setReferenti(prest.data)
    }





    useEffect(() => { //carico clienti subito
        loadReferenti()
    
        //
    }, []);

    useEffect(() => {
        if (referenteSel) {
            document.getElementById("editReferente").click()
        }
    }, [referenteSel])


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div className='w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1'>
                    <div className='flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2'>
                        <GridToolbarQuickFilter placeholder='Cerca...' />
                    </div>
                    <div className='flex justify-center md:justify-end md:mx-0 mx-auto'>

                        <ModalPrestazione tipologia={"inserimento"} loadReferenti={loadReferenti} />
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }



    return (


        <div className="el-container h-fit ">

            <ModalPrestazione tipologia={"modifica"}  referenteSel={referenteSel} loadReferenti={loadReferenti}/>


            <div className='w-full md:h-[70vh] h-[70vh] relative el-container'>

                <DataGridPro
density='compact'
                    slots={{
                        toolbar: CustomToolbar,
                        loadingOverlay: LinearProgress,
                        footer: CustomFooterPersonalized,
                    }}
                    //loading={loading}
                    localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                    rows={referenti}
                    getRowId={(r) => r.id}
                />
            </div>




        </div>
    )

}



const ModalPrestazione = ({ tipologia, referenteSel, loadReferenti}) => {
    const [open, setOpen] = useState(false);
    const [referente, setReferente] = useState(null)

        
    const handleOpenModal = () => {

        if (tipologia === "modifica") {
         
            setReferente(referenteSel)
      
        }else{

            setReferente({
               name:""


            })

        }
        setOpen(true)


    }
    const handleChange = (e) => {

        let r = { ...referente };
       r[e.target.name] = e.target.value;
        setReferente(r);


    }
    const handleSave = async () => {

        try {

            if (tipologia === "inserimento") {
            
                    await api.post("/referenti_accounting", { referente: referente })
               
                    toast.success("Referente inserita con successo")

            } else {
                await api.put("/referenti_accounting", { referente: referente })
                toast.success("Referente modificata con successo")
            }
            loadReferenti();
            setOpen(false)
        } catch {


            toast.error("Errore durante il salvataggio")
        }

    }

    return (<>
        {tipologia === "inserimento" ?
            <Button
                className="success-button"
                text="Aggiungi"
                type="button"
                icon={<HiPlus className="button-icon" />}
                onClick={async () => {
                    handleOpenModal()

                }}
            /> :

            <Button
                className='hidden'
                id="editReferente"
                type='button'
                icon={<MdEdit className='button-icon ' />}
                onClick={async () => {
                    handleOpenModal()
                }}
            />


        }
        <Modal isOpen={open} closeButton={() => setOpen(false)} headerText={`${tipologia} Referente`} size={"medium"} leftButtons={<Button
            className="principal-button"
            text="Salva"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
                handleSave()

            }}
        />}>
            <div className='grid grid-cols-2 gap-2'>
                <div className='col-span-2 '>
                    <Input
                        type="text"
                        label={"Referente"}
                        name="name"
                        onChange={(e) => handleChange(e)}
                        value={referente?.name}
    
                    />
                </div>
               
             
              
            </div>
        </Modal>


    </>)



}