//npm i react-toggle-dark-mode
//darkMode: "class",
//useDarkSide
import { useState } from "react";
import useDarkSide from "./useDarkSide";
import { DarkModeSwitch } from "react-toggle-dark-mode";

export const Switcher = () => {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
    let darkMode = document.getElementById("logoSidebar");
    let darkModeMbl = document.getElementById("logoSidebarMbl");
    if (darkMode) {
      darkMode.src = checked
        ? require("../../assets/images/logo-full.png")
        : require("../../assets/images/logo-white-full.png");
    }
    if (darkModeMbl) {
      darkModeMbl.src = checked
        ? require("../../assets/images/logo-chiaro-sm.png")
        : require("../../assets/images/logo-sm.png");
    }
  };

  return (
    <>
      <DarkModeSwitch
        id="toggleDarkMode"
        checked={darkSide}
        onChange={toggleDarkMode}
        size={30}
        moonColor="rgb(226 232 240)"
        sunColor="#2265B1"
      />
    </>
  );
};
