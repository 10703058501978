import React from "react";
import { HiLockClosed } from "react-icons/hi2";
export const Input = ({ label, type, onChange, value, name, disabled,maxlength,min,max,placeholder }) => {
  return (
    <div className="relative mb-2">
      {" "}
      {/* Add "mb-4" for margin-bottom */}
      <label className="text-gray-500 text-xs font-medium uppercase tracking-wider px-1 left-2 -top-2">
        {label}  {disabled?<HiLockClosed className="inline"/>:""}
      </label>
      <input
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        min={min}
        max={max}
        maxlength={maxlength}
        className={`rounded-md p-2 pl-2 w-full focus:outline-success-200 ${disabled ? "border-2 " : "border-primary-300 border border-gray-300"} `}
        type={type}
        onChange={onChange}
        name={name}
        disabled={disabled}
      />
    </div>
  );
};

