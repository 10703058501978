import { useEffect, useState } from "react";
import {
  HiMagnifyingGlass,
  HiOutlineArrowTopRightOnSquare,
  HiOutlineXMark,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export const SearchPages = ({ links }) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [linksFiltered, setLinksFiltered] = useState([]);

  const handleClose = () => {
    setSearch("");
    setLinksFiltered([]);
    setVisible(false);
  };
  const handleChange = (e) => {
    let userData = e.target.value; //user enetered data
    setSearch(userData);
    let filteredLinks = [];
    let childData = [];
    if (userData) {
      filteredLinks = links.filter((data) => {
        //filtering array value and user characters to lowercase and return only those words which are start with user enetered chars
        console.log(childData)
        if (data.child?.length > 0) {
          childData = data.child.filter((d) => {
            if (
              d.text.toLocaleLowerCase().startsWith(userData.toLocaleLowerCase())
            ) {
              return d;
            }
          });
        }
        if (
          data.text.toLocaleLowerCase().startsWith(userData.toLocaleLowerCase())
        ) {
          return data;
        }
      });
      if (filteredLinks.length > 0 && childData.length > 0) {
        const concatLinks = filteredLinks.concat(childData);
        showSuggestions(concatLinks);
      }else if (childData.length > 0) {
        showSuggestions(childData);
      }else if (filteredLinks.length > 0) {
        showSuggestions(filteredLinks);
      } else {
        showSuggestions([{ id: 0, href: "", text: "Pagina Non Trovata!" }]);
      }
    } else {
      handleClose();
    }
  };
  function showSuggestions(listOfLinks) {
    let listData;
    if (!listOfLinks.length) {
      listData = search;
    } else {
      listData = [...listOfLinks];
    }
    setVisible(true);
    setLinksFiltered(listData);
  }

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          handleClose();
        }}
      >
        <div className="ml-4 py-2 relative">
          <input
            className={`dark:text-slate-200 border-miller-300 bg-white dark:bg-miller-300 dark:border-miller-500 placeholder-miller-300 dark:placeholder-slate-200 h-10 px-5 pr-12 text-sm focus:outline-none ${
              visible ? "rounded-t-lg border-t border-x" : "rounded-lg border"
            }`}
            autoComplete="off"
            type="text"
            name="search"
            value={search}
            onChange={handleChange}
            placeholder="Cerca..."
          />
          <button
            className="absolute right-0 top-0 mt-5 mr-4"
            onClick={() => {
              if (visible) {
                handleClose();
              }
            }}
          >
            {!visible ? (
              <HiMagnifyingGlass className="text-miller-300 dark:text-slate-200" />
            ) : (
              <HiOutlineXMark className="text-miller-300 dark:text-slate-200" />
            )}
          </button>
          <ul
            className={`absolute border-miller-300 border-x border-b h-fit w-full top-12 left-0 bg-white dark:bg-miller-300 dark:border-miller-500 rounded-b-md pb-4 text-sm flex-col z-50 ${
              visible ? "" : "hidden"
            }`}
          >
            {linksFiltered?.map((el) => {
              if (el.id === 0) {
                return (
                  <li className="" key={el.id}>
                    <a className="flex items-start justify-center md:justify-start py-2 space-x-3 rounded-md dark:text-slate-200 dark:hover:text-miller-500 hover:bg-slate-200 cursor-pointer px-5">
                      {el.text}
                    </a>
                  </li>
                );
              } else {
                return (
                  <li className="" key={el.id}>
                    <a
                      className="flex items-start justify-center md:justify-start py-2 space-x-3 rounded-md dark:text-slate-200 dark:hover:text-miller-500 hover:bg-slate-200 cursor-pointer px-5"
                      onClick={() => {
                        handleClose();
                        navigate(el.href);
                      }}
                    >
                      {el.text}
                      <HiOutlineArrowTopRightOnSquare className="text-miller-300 dark:text-miller-500 text-lg ml-2" />
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </ClickAwayListener>
    </>
  );
};
