import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Switcher } from "../components/Switcher/Switcher";
import { Breadcrumb } from "./Breadcrumb";
import { HiOutlineUserCircle } from "react-icons/hi";
import { HiOutlineBars3 } from "react-icons/hi2";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SearchPages } from "./SearchPages";
import { UserProfile } from "./UserProfile";

export default function Sidebar({ links, setLinks }) {
  const [open, setOpen] = useState(true);
  const [fade, setFade] = useState(false);
  const [screenSize, getDimension] = useState(window.innerWidth);

  const [tooltip, setTooltip] = useState("");
  const [childTooltip, setChildTooltip] = useState("");

  const [pathname, setPathname] = useState("");
  const [link, setLink] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 24) {
      setFade(false);
    } else {
      setFade(true);
    }
  };

  let head = document.getElementById("sidebar");
  head?.addEventListener("touchstart", handleTouchStart, false);
  head?.addEventListener("touchmove", handleTouchMove, false);

  var xDown = null;
  var yDown = null;

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;
    //The Math.abs() static method returns the absolute value of a number
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* right swipe */
      } else {
        /* left swipe */
      }
    } else {
      if (yDiff > 0) {
        /* down swipe */
        setOpen(true);
      } else {
        /* up swipe */
        setOpen(false);
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  }

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
      if (screenSize <= "768") {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
  }, [screenSize]);

  useEffect(() => {
    let pathnameNoBackslash = "";
    let linkBread = "";
    if (location.pathname === "/") {
      pathnameNoBackslash = "Home";
    } else if (location.pathname.includes("Dettaglio")) {
      pathnameNoBackslash = location.pathname.replace("/", "");

      let firstStep = location.pathname.split("Dettaglio");
      linkBread = "Dettaglio " + firstStep[1];
    } else {
      pathnameNoBackslash = location.pathname.replace("/", "");
    }

    setLink(linkBread);
    setPathname(pathnameNoBackslash);
  }, [navigate]);
  return (
    <>
      <div className="flex w-full items-start relative dark:bg-white">
        <ClickAwayListener
          onClickAway={() => {
            if (!open && screenSize <= "768") {
              setOpen(true);
            }
          }}
        >
          <div
            id="sidebar"
            className={` ${
              open ? "md:w-20 md:items-center h-[78px]" : "md:w-60 "
            } md:flex md:flex-col md:h-screen md:overflow-y-auto md:p-3 md:sticky md:top-0 w-full absolute p-6 bg-white dark:bg-miller-300 duration-500 transition-all z-50 md:z-10`}
          >
            <div className="space-y-3">
              <div
                className={`flex md:flex-row flex-row-reverse items-center ${
                  !open ? "justify-between" : "justify-center"
                }`}
              >
                {!open && screenSize > "768" ? (
                  <>
                    <img
                      onClick={() => setOpen(!open)}
                      id="logoSidebar"
                      src={
                        localStorage.getItem("theme") === "light"
                          ? require("../assets/images/logo-white-full.png")
                          : require("../assets/images/logo-full.png")
                      }
                      className="w-10 md:w-32"
                    />
                  </>
                ) : (
                  <>
                    <div className="flex-1 md:hidden"></div>
                    <div
                      onClick={() => setOpen(!open)}
                      className={`flex-1 md:hidden absolute top-[100%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-4 py-4 rounded-full dark:bg-miller-300 bg-white  z-10`}
                    >
                      <img
                        id="logoSidebarMbl"
                        src={
                          localStorage.getItem("theme") === "light"
                            ? require("../assets/images/logo-sm.png")
                            : require("../assets/images/logo-chiaro-sm.png")
                        }
                        className={`w-12 rounded-full mx-auto duration-500  ${
                          !open ? "rotate-180" : ""
                        }`}
                      />
                    </div>
                    <div className="flex-1 md:hidden absolute top-[100%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-2 dark:bg-miller-500 bg-miller-200 z-0"></div>
                  </>
                )}
                <div
                  className={`w-full md:hidden flex items-center justify-end space-x-4 ${
                    !open ? "hidden" : "flex"
                  }`}
                >
                  <UserProfile />
                  <Switcher />
                </div>
                <button
                  className={`flex-1 ${!open ? "hidden" : "block"}`}
                  onClick={() => setOpen(!open)}
                >
                  <HiOutlineBars3 className="dark:text-white text-miller-300 text-3xl font-extrabold md:mx-auto" />
                </button>
              </div>

              <div
                className={` ${
                  !open ? "flex justify-center" : "md:flex hidden"
                }`}
              >
                <ul className="md:pt-2 pb-4 mb-4 space-y-1 text-sm flex-col">
                  {links
                    ?.filter((el) => el.visibility)
                    .map((el) => {
                      return (
                        <>
                          <li
                            className="rounded-sm group relative"
                            key={el.id}
                            id={el.id}
                            onMouseOver={() => {
                              if (open) {
                                setTooltip(el.text);
                                let li = document.getElementById(el.id)
                                  .children[0];
                                li.style.display = "flex";
                              }
                            }}
                            onMouseLeave={() => {
                              if (open) {
                                setTooltip("");
                                let li = document.getElementById(el.id)
                                  .children[0];
                                li.style.display = "none";
                              }
                            }}
                          >
                            <div
                              className={
                                " absolute -top-3.5 left-1/2 transform -translate-x-1/2"
                              }
                              style={{ display: "none" }}
                            >
                              <span
                                className={
                                  "text-center w-16 text-xs truncate dark:text-miller-500 text-miller-300 font-black  duration-300 transition-all capitalize"
                                }
                              >
                                {tooltip}
                              </span>
                            </div>
                            <a
                              onClick={() => {
                                if (el.func) {
                                  el.func();
                                } else if (el.child?.length > 0) {
                                  let l = [...links];
                                  l.find((o, i) => {
                                    //inizializzo il cerca
                                    if (o.id === el.id) {
                                      //cerco l'id del link selezionato
                                      let newVisibility = { ...l[i] }; //copio i valori dell'oggetto
                                      newVisibility.childVisibility =
                                        !el.childVisibility; //aggiorno la visibilità
                                      l[i] = { ...newVisibility }; //carico i nuovi valori
                                      return true; // stop searching
                                    }
                                  });
                                  setLinks(l);
                                } else {
                                  navigate(el.href);
                                  setOpen(true);
                                }
                              }}
                              className={
                                el.child?.length > 0
                                  ? `flex items-center justify-center md:justify-start p-2 rounded-md  hover:bg-slate-200 cursor-pointer `
                                  : open
                                  ? `li-container `
                                  : "li-container md:justify-start"
                              }
                            >
                              {screenSize > "768" ? el.icon : <></>}
                              {!open ? (
                                <span
                                  className={
                                    el.child?.length > 0
                                      ? "li-text ml-3 "
                                      : "li-text"
                                  }
                                >
                                  {el.text}
                                </span>
                              ) : (
                                <></>
                              )}
                              {el.child?.length > 0 ? (
                                <>
                                  {el.childVisibility ? (
                                    <IoMdArrowDropup className="li-icon" />
                                  ) : (
                                    <IoMdArrowDropdown className="li-icon" />
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </a>
                            {el.child?.length > 0 ? (
                              <ul
                                className={`py-1 space-y-2 text-base flex-col  ${
                                  el.childVisibility ? "flex" : "hidden"
                                }`}
                              >
                                {el.child?.map((elSon) => {
                                  return (
                                    <>
                                      <li
                                        className="rounded-sm relative"
                                        key={elSon.id}
                                        id={elSon.id}
                                        onMouseOver={() => {
                                          if (open) {
                                            setChildTooltip(elSon.text);
                                            let li = document.getElementById(
                                              elSon.id
                                            ).children[0];
                                            li.style.display = "flex";
                                            console.log(li);
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          if (open) {
                                            setChildTooltip("");
                                            let li = document.getElementById(
                                              elSon.id
                                            ).children[0];
                                            li.style.display = "none";
                                          }
                                        }}
                                      >
                                        <div
                                          className={
                                            " absolute -top-3 left-1/2 transform -translate-x-1/2"
                                          }
                                          style={{ display: "none" }}
                                        >
                                          <span
                                            className={
                                              "text-center w-16 text-xs truncate dark:text-slate-200 text-miller-300 group-hover:dark:text-miller-300 font-black  duration-300 transition-all capitalize"
                                            }
                                          >
                                            {childTooltip}
                                          </span>
                                        </div>
                                        <a
                                          onClick={() => {
                                            if (elSon.func) {
                                              elSon.func();
                                            } else {
                                              navigate(elSon.href);
                                              setOpen(true);
                                            }
                                          }}
                                          className="child-li-container"
                                        >
                                          {screenSize > "768" ? (
                                            elSon.icon
                                          ) : (
                                            <></>
                                          )}
                                          {!open ? (
                                            <span className="child-li-text">
                                              {elSon.text}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                        </a>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            ) : (
                              <></>
                            )}
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </ClickAwayListener>
        <div
          id="body"
          onScroll={handleScroll}
          onClick={() => {
            if (screenSize <= "768" && !open) {
              setOpen(!open);
            }
          }}
          className={` overflow-y-hidden w-full flex flex-wrap justify-start relative z-10 md:mt-0 mt-[80px]`} //md:max-h-[90vh] max-h-[85vh] md:min-h-[90vh] min-h-[85vh]
        >
          <div
            id="header"
            className={`w-full md:h-14  hidden md:flex justify-between `} //transition-all duration-200 ${fade ? "opacity-100" : "opacity-0 hidden"}
          >
            <SearchPages links={links} />
            <div className="flex place-items-center px-4 space-x-4">
              <UserProfile />
              <Switcher />
            </div>
          </div>
          <Breadcrumb pathname={pathname} link={link} />
          <Outlet />
        </div>
      </div>
    </>
  );
}

/* 
linkExample = [
  id: 5,
  href: "/Parcellazione",
  icon: <MdTableChart className="li-icon" />,
  text: "PARCELLAZIONE",
  childVisibility: false,
  child: [
    {
      id: 1,
      href: "/Home",
      icon: <MdHome className="child-li-icon" />,
      text: "Home",
    },
    {
      id: 2,
      href: "/Home",
      icon: <MdHome className="child-li-icon" />,
      text: "Home",
    },
    {
      id: 3,
      href: "/Home",
      icon: <MdHome className="child-li-icon" />,
      text: "Home",
    },
  ],
]
*/
