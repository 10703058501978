import React, { useState } from "react";

import {
    DataGridPro,
    itIT,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import Modal from "../../components/Modal";
import LinearProgress from '@mui/material/LinearProgress';
import { CustomFooterPersonalized } from "../../components/DataGrid/CustomFooterPersonalized";
import { Button } from "../../components/Button";
import { MdEdit } from "react-icons/md";
import api from "../../api/api";
import { toast } from "react-toastify";
import { Input } from "../../components/Input";
import { HiOutlineCheck, HiPlus } from "react-icons/hi2";
import { Select } from "../../components/Select";
import { handleCalcolo } from "../../utils/utils";

export const Zucchetti = ({ aziende, screenSize, data, loadAziende }) => {
    const [azienda, setAzienda] = useState(null);
    const columns = [
        {
            field: 'Azioni',
            headerName: 'Azioni',

            headerAlign: 'center',
            align: 'center',
            filterable: false,
            renderCell: (params) => {
                return (
                    <>
                        <div className='hidden md:flex flex-nowrap gap-2'>
                            <Button
                                className='edit-button '
                                id="editEmail"
                                type='button'
                                icon={<MdEdit className='button-icon' />}
                                onClick={async () => {
                                    await setAzienda(params.row);

                                    document.getElementById("editRowZucchetti").click();
                                }}
                            />
                        </div>
                    </>)
            }
        },
        {
            field: 'codice_univoco',
            headerName: 'Cod. Univoco',
            width: 150,
            flex: screenSize > '768' ? 1 : 0,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'codice_fiscale',
            headerName: 'Cod. Fiscale',
            width: 150,
            flex: screenSize > '768' ? 1 : 0,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'codice_parcellazione',
            headerName: 'Cod. Parcellazione',
            width: 150,
            flex: screenSize > '768' ? 1 : 0,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'ragione_sociale',
            headerName: 'Ragione Sociale',
            width: 150,
            flex: screenSize > '768' ? 1 : 0,
            headerAlign: 'center',
            align: 'center',
        },

        {
            field: 'referente_attivo_payroll',
            headerName: 'Referente Payroll 1',
            width: 150,
            flex: screenSize > '768' ? 1 : 0,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'referente_attivo_assunzioni',
            headerName: 'Referente Assunzioni 1',
            width: 150,
            flex: screenSize > '768' ? 1 : 0,
            headerAlign: 'center',
            align: 'center',
        },
    ];
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
                    <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
                        <GridToolbarQuickFilter placeholder='Cerca...' />
                    </div>

                </div>
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <ModalParcellazione aziendaSel={azienda} data={data} loadAziende={loadAziende} />
            <div className='w-full md:h-[70vh] h-[70vh] relative el-container'>
                <DataGridPro
density='compact'
                    slots={{
                        toolbar: CustomToolbar,
                        loadingOverlay: LinearProgress,
                        footer: CustomFooterPersonalized,
                    }}
                    //loading={loading}
                    localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                    rows={aziende}
                    getRowId={(r) => r.codice_univoco}
                />
            </div>
        </>
    )



}








const ModalParcellazione = ({ aziendaSel, data, loadAziende }) => {
    const [open, setOpen] = useState(false)
    const [azienda, setAzienda] = useState(null)
    const [listino, setListino] = useState(null) //listino prestazioni
    const [dettaglioParcellazione, setDettaglioParcellazione] = useState([]) //dettaglio prestazioni
    const columns = [

        {
            field: "prestazione",
            headerName: "Codice",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "descrizione",
            headerName: "Descrizione",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "note",
            headerName: "Note",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "importo",
            headerName: "Importo",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
                    <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
                        <GridToolbarQuickFilter placeholder="Cerca..." />
                    </div>
                    <Button
                        className="principal-button "
                        text="Aggiungi"
                        type="button"
                        icon={<HiPlus className="button-icon" />}
                        onClick={async () => {
                            // handleClickOpen() 
                            document.getElementById("modalInserimentoParce").click();
                        }}
                    />
                </div>
            </GridToolbarContainer>
        );
    }
    const loadListino = async () => {
        try {
            const risp = await api.get(`/prestazione_azienda/listino/${aziendaSel.codice_univoco}`);
       
            setListino(risp.data)

        } catch {
            toast.error("Errore durante il caricamento")
        }

    }

    const handleOpen = async () => {

        if (!aziendaSel.id_parcellazione) {
            try {


                let res = await api.post("/parcellazione/zucchetti", { azienda: aziendaSel.codice_univoco, data: data })
                let az = { ...aziendaSel };
                az.id_parcellazione = res.insertId;
                setAzienda(az)
                await loadDettaglioParcellazione(res.insertId)


            } catch {

                toast.error("ERRORE codice MT-0001 si prega di riprovare o di segnalare")
            }


        
        } else {
            setAzienda(aziendaSel)
      
            await loadDettaglioParcellazione(aziendaSel.id_parcellazione)
        }


        await loadListino();
        setOpen(true)




    }

    const handleClose = async () => {
        await loadAziende()
        setOpen(false)
    }

    const handleChange = (e) => {
        console.log(e.target.value)
        let az = { ...azienda };
        az[e.target.name] = e.target.value;
        setAzienda(az)
        console.log(az)
    }

    const handleSave = async () => {
        // await loadAziende()

        try {
            api.put("/parcellazione/zucchetti", { parcellazione: azienda })
            toast.success("Salvataggio avvenuto con successo");
        } catch {
            toast.error("Errore nel salvataggio");
        }

    }

    const loadDettaglioParcellazione = async (id) => {
        try {
            const risp = await api.get(`/dettaglio_parcellazione/zucchetti/${id}`);
    
            setDettaglioParcellazione(risp.data)

        } catch {
            toast.error("Errore durante il caricamento")
        }




    }

    return (<>

        <button className="hidden" onClick={handleOpen} id="editRowZucchetti"> </button>
        <Modal isOpen={open} closeButton={handleClose} headerText={<div className="flex flex-row gap-2"><p>Parcellazione</p><input type="month" disabled={true} value={data}></input></div>}
            leftButtons={<Button
                className="principal-button z-[110]"
                text={"Salva"}
                type="button"
                icon={<HiOutlineCheck className="button-icon" />}
                onClick={async () => {
                    await handleSave()
                    await loadDettaglioParcellazione(azienda?.id_parcellazione)
                }}
            />}
        >
            <ModalDettaglioParcellazione loadDettaglioParcellazione={loadDettaglioParcellazione} listino={listino} cedolini_elaborati={aziendaSel?.cedolini_elaborati} azienda={aziendaSel}/>
            <div className="grid grid-cols-4 gap-2">
                <div className="col-span-4 justify-self-center font-black">
                    {azienda?.ragione_sociale}
                </div>
               {/*} <div><Input
                    type="text"
                    label={"Cedolini elaborati"}
                    name="cedolini_elaborati"
                    onChange={(e) => handleChange(e)}
                    value={azienda?.cedolini_elaborati}
                    disabled={false}
                /></div>
                <div><Input
                    type="text"
                    label={"Cedolini intermittenti"}
                    name="cedolini_intermittenti"
                    onChange={(e) => handleChange(e)}
                    value={azienda?.cedolini_intermittenti}
                    disabled={false}
                /></div>
                <div><Input
                    type="text"
                    label={"Cedolini somministrati"}
                    name="cedolini_somministrati"
                    onChange={(e) => handleChange(e)}
                    value={azienda?.cedolini_somministrati}
                    disabled={false}
                /></div>
                <div><Input
                    type="text"
                    label={"Cedolini 13/14"}
                    name="cedolini_13_14"
                    onChange={(e) => handleChange(e)}
                    value={azienda?.cedolini_13_14}
                    disabled={false}
            /></div>*/}
                   

                <div className="col-span-4 h-[50vh] ">
                    <DataGridPro
density='compact'
                        slots={{
                            toolbar: CustomToolbar,
                            loadingOverlay: LinearProgress,
                            footer: CustomFooterPersonalized,
                        }}
                        //loading={loading}
                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        rows={dettaglioParcellazione}
                        getRowId={(r) => r.id}
                    />
                </div>


            </div>


        </Modal>


    </>)







}






const ModalDettaglioParcellazione = ({ listino, tipologia,cedolini_elaborati,azienda,loadDettaglioParcellazione }) => {
    const [open, setOpen] = useState(false)
	const [prestazioneSel, setPrestazioneSel] = useState(null)
	const [importo, setImporto] = useState(0)
	const [totale, setTotale] = useState(0)
	const [note, setNote] = useState("");
	const [quantita, setQuantita] = useState("");
	const [extra, setExtra] = useState("")

	const handleClickOpen = () => {
		setImporto(0)
		setNote("")
		setExtra("")
		setQuantita(0)	
		setTotale(0)
		setOpen(true)
		setPrestazioneSel(null)
	}


	const handleChangeQuantita =(e) =>{


		setQuantita(e.target.value)
		setTotale(e.target.value * importo)
	}

	const handleChangeImporto =(e) =>{


		setImporto(e.target.value)
		setTotale(e.target.value * quantita)
	}



	const handleChangePrestazione = (e) => {

		let prestazione_temp = listino.filter(el => el.codice == e.target.value)
		setPrestazioneSel(prestazione_temp[0])
		setImporto(prestazione_temp[0].importo?prestazione_temp[0].importo:0)
		setNote("")
		setExtra("")
		setTotale(0)
		setQuantita(1)
	}

	const handleSave = async () => {
		try {
			await api.post("/dettaglio_parcellazione/zucchetti", { parcellazione: azienda.id_parcellazione, prestazione: prestazioneSel.codice, importo: importo, note: note,quantita:quantita,totale:totale,extra:extra })
			await loadDettaglioParcellazione(azienda.id_parcellazione);
			toast.success("prestazione inserita con successo!")
		} catch {

			toast.error("Errore durante l'inserimento della prestazione")

		}
	}

    return (<>
        <Button
            className="hidden "

            id="modalInserimentoParce"
            text="Aggiungi"
            type="button"
            icon={<HiPlus className="button-icon" />}
            onClick={async () => {
                handleClickOpen()
            }}
        />
        <Modal isOpen={open} closeButton={() => setOpen(false)} headerText={"Inserisci prestazione"}
          leftButtons={<Button
            className="principal-button z-[110]"
            text={"Salva"}
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
                handleSave()
            }}
        />}
        >

<div className="grid grid-cols-4 gap-2">

<div className="col-span-4">
    <Select
        type="text"
        label={"Prestazione"}
        name="prestazione_sel"
        onChange={(e) => handleChangePrestazione(e)}
        //value={prestazioneSel?.codice
        placeholder="Seleziona..."
        options={listino?.map((el) => { return { id: el.codice, name: el.codice + " - " + el.descrizione } })}
        disabled={tipologia === "modifica"}
    />
</div>
<div className="col-span-2 md:col-span-1">
    <Input
        type="number"
        label={"Quantità"}
        name="quantita"
        onChange={(e) => handleChangeQuantita(e)}
        value={quantita}
        disabled={prestazioneSel?.metodo_fatturazione == 1}
    />
</div>

<div className="col-span-2 md:col-span-1">
    <Input
        type="number"
        label={"Importo"}
        name="importo"
        onChange={(e) => handleChangeImporto(e)}
        value={importo}
        disabled={prestazioneSel?.metodo_fatturazione ==1}
    />
</div>
<div className="col-span-4 md:col-span-2">
    <Input
        type="text"
        label={"Totale"}
        name="totale"
        onChange={(e) => setImporto(e.target.value)}
        value={totale}
        disabled={prestazioneSel?.metodo_fatturazione != 2}
    />
</div>
<div className="col-span-4 md:col-span-2">
    <Input
        type="text"
        label={"descrizione fattura"}
        placeholder={prestazioneSel?.richiesta}
        name="extra_in_fattura"
        onChange={(e) => setExtra(e.target.value)}
        value={extra}
        disabled={false}
    />
</div>

<div className="col-span-4 md:col-span-2">
    <Input
        type="text"
        label={"Note"}
        name="Note"
        onChange={(e) => setNote(e.target.value)}
        value={note}
        disabled={false}
    />
</div>


</div>

        </Modal>


    </>)





}