import axios from "axios";

//const BASE_URL = "http://localhost:3000/api/";
//const BASE_URL = "http://10.1.2.38:3000/api/";
const BASE_URL = "https://crm.millergroup.it/api/";

export default axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
