import React from "react";

export const PrincipalButton = ({ button }) => {
  return (
    <div className="absolute top-20 md:top-16 right-0 z-[50]">
          {button}
    </div>
  );
};

/* 
<Button
  className= --> "success-button" / "error-button" 
  text={"Conferma"}
  onClick={() => handleDelete()}
  icon={<AiOutlineCheck className="button-icon" />}
/>
*/
