import { useState, useEffect } from "react";
import { TabPanel } from "../components/TabPanel";
import {
  MdBarChart,
  MdCheck,
  MdContactPhone,
  MdContentPaste,
  MdPayments,
} from "react-icons/md";

import api from "../api/api.js";
import { toast } from "react-toastify";
import moment from "moment";
import { Cedolini } from "./Statistiche/Cedolini.js";
import { ReferentiPaghe } from "./Statistiche/ReferentiPaghe.js";
import { HiOutlineHomeModern } from "react-icons/hi2";
import { Clienti } from "./Statistiche/Clienti.js";
import { HiGlobe } from "react-icons/hi";
import { Globale } from "./Statistiche/Globale.js";

export const Statistiche = ({ screenSize }) => {
  const [today, setToday] = useState(moment(new Date()).format("YYYY-MM"));
  const [giornof24, setGiornoF24] = useState(null);

  const [tab, setTab] = useState(1);
  const [tabs, setTabs] = useState([
    {
      icon: <HiGlobe size={30} className="tab-icon" />,
      text: "Globale",
      id: 1,
    },
    {
      icon: <HiOutlineHomeModern size={30} className="tab-icon" />,
      text: "Clienti",
      id: 2,
    },
    {
      icon: <MdContentPaste size={30} className="tab-icon" />,
      text: "Referenti",
      id: 3,
    },
    {
      icon: <MdContentPaste size={30} className="tab-icon" />,
      text: "Andamento Referenti",
      id: 4,
    },
  ]);
  let content = [
    {
      child: (
        <Globale
          screenSize={screenSize}
          f24={giornof24?.ID}
          dataF24={
            giornof24?.giorno + "/" + giornof24?.mese + "/" + giornof24?.anno
          }
        />
      ),
      id: 1,
    },
    {
      child: (
        <Clienti
          screenSize={screenSize}
          f24={giornof24?.ID}
          dataF24={
            giornof24?.giorno + "/" + giornof24?.mese + "/" + giornof24?.anno
          }
        />
      ),
      id: 2,
    },
    {
      child: (
        <Cedolini
          screenSize={screenSize}
          f24={giornof24?.ID}
          dataF24={
            giornof24?.giorno + "/" + giornof24?.mese + "/" + giornof24?.anno
          }
        />
      ),
      id: 3,
    },
    {
      child: (
        <ReferentiPaghe
          screenSize={screenSize}
          f24={giornof24?.ID}
          dataF24={
            giornof24?.giorno + "/" + giornof24?.mese + "/" + giornof24?.anno
          }
        />
      ),
      id: 4,
    },
  ];

  useEffect(() => {
    loadGiornoF24();
  }, [today]);

  const loadGiornoF24 = async () => {
    try {
      const f24 = await api.post(`/f24/giorno`, {
        mese: today.split("-")[1],
        anno: today.split("-")[0],
      });
      setGiornoF24(f24.data);
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };

  return (
    <>
      <div className="mx-auto w-full page-container gap-4">
        <TabPanel tab={tab} setTab={setTab} tabs={tabs} content={content} />

        {/* <div className="flex w-full min-h-fit justify-end">
        <Button
          className="success-button"
          text="salva"
          type="button"
          icon={<MdCheck className="button-icon" />}
          onClick={async () => {
            console.log("salva");
          }}
        />
      </div> */}
      </div>
    </>
  );
};
