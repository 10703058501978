import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { PrincipalButton } from "../../components/PrincipalButton";
import {
  HiFingerPrint,
  HiLockOpen,
  HiOutlineCheck,
  HiOutlineLockClosed,
  HiOutlineLockOpen,
  HiOutlinePencil,
  HiOutlinePlus,
  HiOutlineTrash,
  HiOutlineXMark,
  HiPlus,
} from "react-icons/hi2";
import moment from "moment";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import { CustomFooterPersonalized } from "../../components/DataGrid/CustomFooterPersonalized";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SlOptionsVertical } from "react-icons/sl";
import Modal from "../../components/Modal";
import api from "../../api/api";
import { Select } from "../../components/Select";
import { toast } from "react-toastify";
import { MdEdit } from "react-icons/md";
import { Input } from "../../components/Input";
import { CustomCheckbox } from "../../components/Checkbox";
import { createPortal } from "react-dom";
import { knowAcces } from "../../utils/utils";

export const ConIncarico = ({
  screenSize,
  f24,
  dataF24,
  giornof24,
  loadGiornoF24,
}) => {
  const [self24, setSelF24] = useState(null);
  const [referenti_esterni, setReferentiEsterni] = useState([]);
  const [elenco_iban, setElencoIban] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const anchorRef = useRef();
  const actionsButton = useRef();
  const [buttonVisible, setButtonVisible] = useState(false);
  const [tipologia_f24, setTipologiaF24] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [elencoF24, setElencoF24] = useState([]);
  const [elencoF24Check, setElencoF24Check] = useState([]);
  const [ibanDiego, setIbanDiego] = useState(false);
  useEffect(() => {}, [screenSize]);

  const handleToggleButton = () => {
    setButtonVisible(!buttonVisible);
  };

  useEffect(() => {
    loadF24ConIncarico();
  }, [f24]);

  useEffect(() => {
    loadIbanDiego();
  }, []);

  const loadF24ConIncarico = async () => {
    if (f24) {
      const responsef24 = await api.get(`/f24/con_incarico/${f24}`, {
        params: { dataF24: dataF24 },
      });

      setElencoF24(responsef24.data);
    }
  };
  const loadIbanDiego = async () => {
    const responseiban = await api.get(`/settings/iban_diego`);
    setIbanDiego(responseiban.data[0].iban_diego);
  };
  const loadModal = async (row) => {
    setSelF24(row);
    await loadReferenti(row);
    await loadIban(row);
    await loadAltriF24();
    document.getElementById("dettaglioF24").click();
  };
  const loadReferenti = async (row) => {
    const refEst = await api.get(
      `/referenti_aziende_esterno/${row.codice_univoco}`
    );
    setReferentiEsterni(refEst.data.filter((el) => el.tipologia === 3));
  };
  const loadIban = async (row) => {
    const elIb = await api.get(`/iban/${row.codice_univoco}`);
    setElencoIban(elIb.data);
  };
  const loadAltriF24 = async (row) => {
    const tip_f24 = await api.get(`/tipologia_f24`);

    let data = !knowAcces(["f24", "team_leader", "admin"])
      ? tip_f24.data.filter((el) => el.id != 4)
      : tip_f24.data;

    setTipologiaF24(data);
  };

  const columns = [
    {
      field: "inizio_incarico",
      headerName: "Data inizio incarico",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        moment(params.row.inizio_incarico).format("DD/MM/YYYY"),
      valueGetter: (params) =>
        moment(params.row.inizio_incarico).format("DD/MM/YYYY"),
    },
    {
      field: "fine_incarico",
      headerName: "Data fine incarico",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.fine_incarico
          ? moment(params.row.fine_incarico).format("DD/MM/YYYY")
          : "",
      valueGetter: (params) =>
        params.row.fine_incarico
          ? moment(params.row.fine_incarico).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "codice_univoco",
      headerName: "Codice Univoco",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ragione_sociale",
      headerName: "Ragione Sociale",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "descrizione_payroll",
      headerName: "Referente",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tipologia_serv",
      headerName: "Tipologia",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "iban",
      headerName: "IBAN",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "importo",
      headerName: "Importo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.id_stato_extra > 0
          ? params.row.desc_stato_f24
          : params.row.importo,
    },
    {
      field: "pagamento_parziale",
      headerName: "Pagamento Parziale",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.giorno_f24) {
          return <CustomCheckbox value={params.row.pagamento_parziale === 1} />;
        } else {
          return "";
        }
      },
    },
    {
      field: "pagato",
      headerName: "Versato",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.giorno_f24) {
          return <CustomCheckbox value={params.row.pagato === 1} />;
        } else {
          return "";
        }
      },
    },
    {
      field: "quietanza_disponibile",
      headerName: "Quietanza disponibile",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.giorno_f24) {
          return (
            <CustomCheckbox value={params.row.quietanza_disponibile === 1} />
          );
        } else {
          return "";
        }
      },
    },
  ];

  const columnsCheckF24 = [
    {
      field: "codice_univoco",
      headerName: "Codice Univoco",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ragione_sociale",
      headerName: "Ragione Sociale",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "importo_inserito",
      headerName: "Importo inserito",
      flex: 1,
      headerAlign: "center",
      align: "center",
      cellClassName: (params) => {
        if (params.value) {
          return "goodCell";
        } else {
          return "badCell";
        }
      },
    },
    {
      field: "importo_da_zucchetti",
      headerName: "Importo ricevuto",
      flex: 1,
      headerAlign: "center",
      align: "center",
      cellClassName: (params) => {
        if (params.value) {
          return "goodCell";
        } else {
          return "badCell";
        }
      },
    },
    {
      field: "iban_inserito",
      headerName: "IBAN inserito",
      flex: 1,
      headerAlign: "center",
      align: "center",
      cellClassName: (params) => {
        if (params.value) {
          return "goodCell";
        } else {
          return "badCell";
        }
      },
    },
    {
      field: "iban_da_zucchetti",
      headerName: "IBAN ricevuto",
      flex: 1,
      headerAlign: "center",
      align: "center",
      cellClassName: (params) => {
        if (params.value) {
          return "goodCell";
        } else {
          return "badCell";
        }
      },
    },
    {
      field: "stato",
      headerName: "Stato",
      flex: 1,
      headerAlign: "center",
      align: "center",
      cellClassName: (params) => {
        if (params.value == "CORRETTO") {
          return "goodCellStato";
        } else {
          return "badCell";
        }
      },
    },
  ];

  const checkf24 = async () => {
    try {
      setLoading(true);
      setOpenModal(true);
      const f24 = await api.get(`/f24/checkF24`, {
        params: { giornof24: giornof24 },
      });

      setElencoF24Check(f24.data);

      setLoading(false);
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };

  const blockEdit = () => {
    setOpenBlockModal(true);
  };

  const handleDayBlock = async () => {
    try {
      let update = await api.put(`/f24/blocco/${giornof24?.ID}`, {
        stato: giornof24?.inibito == 1 ? 0 : 1,
      });
      loadGiornoF24();
      toast.success(
        giornof24?.inibito == 1
          ? "sbloccato con successo"
          : "bloccato con successo"
      );
      setOpenBlockModal(false);
    } catch {
      toast.error("Errore durante il salvataggio");
    }
  };

  function CustomToolbar(btn) {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          {!knowAcces(["f24"]) && giornof24?.inibito == 1 && (
            <div className="flex justify-center md:justify-end md:mx-0 mx-auto gap-2">
              {
                /*Check se il giorno odierno supera il giorno f24*/ <Button
                  className="success-button"
                  size="md"
                  text={"Modifiche bloccate"}
                  icon={<HiOutlineLockClosed className="button-icon mr-2" />}
                  onClick={() =>
                    alert(
                      "ATTENZIONE, Le modifiche del mese corrente sono bloccate."
                    )
                  }
                />
              }
            </div>
          )}
          {btn && knowAcces(["admin", "f24", "team_leader"]) && (
            <div className="flex justify-center md:justify-end md:mx-0 mx-auto gap-2">
              {
                /*Check se il giorno odierno supera il giorno f24*/ new Date() >
                  new Date(
                    giornof24?.anno,
                    giornof24?.mese - 1,
                    giornof24?.giorno
                  ) && (
                  <Button
                    className="success-button"
                    size="md"
                    text={
                      giornof24?.inibito == 1
                        ? "Modifiche bloccate"
                        : "Modifiche Sbloccate"
                    }
                    icon={
                      giornof24?.inibito == 1 ? (
                        <HiOutlineLockClosed className="button-icon mr-2" />
                      ) : (
                        <HiOutlineLockOpen className="button-icon mr-2" />
                      )
                    }
                    onClick={blockEdit}
                  />
                )
              }
              <Button
                className="success-button"
                size="md"
                text={"Check F24"}
                icon={<HiFingerPrint className="button-icon " />}
                onClick={checkf24}
              />
            </div>
          )}
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <>
      {openBlockModal &&
        createPortal(
          <Modal
            isOpen={openBlockModal}
            closeButton={() => setOpenBlockModal(false)}
            leftButtons={
              <Button
                className="success-button"
                size="md"
                text={"Conferma"}
                icon={<HiOutlineCheck className="button-icon" />}
                onClick={handleDayBlock}
              />
            }
          >
            <div className="el-container ">
              {giornof24?.inibito == 1
                ? "Attenzione, sei sicuro di voler sbloccare le modifiche per il mese selezionato?"
                : "Attenzione, sei sicuro di voler inibire le modifiche per il mese selezionato?"}
            </div>
          </Modal>,
          document.getElementById("portalModal")
        )}

      {openModal &&
        createPortal(
          <Modal isOpen={openModal} closeButton={() => setOpenModal(false)}>
            {loading ? (
              <center className="animate-pulse">Caricamento</center>
            ) : (
              <div className="el-container h-[300px] md:h-[500px]">
                <DataGridPro
                  density="compact"
                  slots={{
                    toolbar: () => CustomToolbar(false),
                    loadingOverlay: LinearProgress,
                    footer: CustomFooterPersonalized,
                  }}
                  //loading={loading}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  columns={columnsCheckF24}
                  rows={elencoF24Check}
                  getRowId={(r) => r.codice_univoco}
                />
              </div>
            )}
          </Modal>,
          document.getElementById("portalModal")
        )}
      <ModalF24
        tipologia={"inserimento"}
        ibanDiego={ibanDiego}
        giornof24={giornof24}
        tipologia_f24={tipologia_f24}
        idF24={f24}
        self24={self24}
        dataF24={dataF24}
        elenco_iban={elenco_iban}
        referenti_esterni={referenti_esterni}
        loadF24ConIncarico={loadF24ConIncarico}
      />
      <div className="el-container h-[300px] md:h-[500px]">
        <DataGridPro
          density="compact"
          slots={{
            toolbar: () => CustomToolbar(true),
            loadingOverlay: LinearProgress,
            footer: CustomFooterPersonalized,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                inizio_incarico: false,
                fine_incarico: false,
              },
            },
          }}
          //loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={elencoF24}
          getRowId={(r) => r.codice_univoco}
          onRowClick={async (params) => {
            await loadModal(params.row);
          }}
        />
      </div>
    </>
  );
};

const ModalF24 = ({
  tipologia_f24,
  idF24,
  tipologia,
  self24,
  dataF24,
  elenco_iban,
  referenti_esterni,
  loadF24ConIncarico,
  giornof24,
  ibanDiego,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedF24, setSelectedF24] = useState(null);

  const handleClickOpen = async () => {
    let s = { ...self24 };

    if (!s.giorno_f24 && elenco_iban.length > 0) {
      s.id_iban = elenco_iban[0].ID;
    }

    if (self24.ibanDiego == 1) {
      // se l'iban è quello di diego
    }

    setSelectedF24(s);
    setOpen(true);
  };

  const handleChange = (e) => {
    let sf24 = { ...selectedF24 };
    sf24[e.target.name] = e.target.value;
    setSelectedF24(sf24);
  };

  const handleChangeSelect = (e, name, updateimp) => {
    let sf24 = { ...selectedF24 };
    sf24[name] = e.target.value;
    setSelectedF24(sf24);
    if (updateimp) {
      sf24.importo = 0;
    } // se cambia stato riporto l'importo a 0
    setSelectedF24(sf24);
  };

  const handleChangeCheckbox = (e, name) => {
    let sf24 = { ...selectedF24 };
    sf24[name] = e.target.checked ? 1 : 0;
    setSelectedF24(sf24);
  };

  const handleSave = async () => {
    try {
      if (selectedF24.giorno_f24) {
        let update = await api.put("/f24", { f24: selectedF24 });
        toast.success("f24 modificato con successo");
      } else {
        let insert = await api.post("/f24", { f24: selectedF24, idF24: idF24 });
        toast.success("f24 creato con successo");
      }
    } catch {
      toast.error("errore durante il salvataggio");
    }

    await loadF24ConIncarico();
  };

  return (
    <>
      <Button
        id="dettaglioF24"
        className="hidden"
        text="Aggiungi"
        type="button"
        icon={<HiPlus className="button-icon" />}
        onClick={async () => {
          await handleClickOpen();
        }}
      />

      <Modal
        isOpen={open}
        closeButton={() => setOpen(false)}
        headerText={self24?.ragione_sociale + " - " + dataF24}
        leftButtons={
          <Button
            className="principal-button z-[110]"
            text={tipologia === "elimina" ? "Elimina" : "Salva"}
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={() => handleSave()}
          />
        }
      >
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-2 md:col-span-1">
            <Input
              type="number"
              label={"importo (F24)"}
              name="importo"
              onChange={(e) => handleChange(e)}
              value={selectedF24?.importo}
              disabled={
                giornof24?.inibito == 1 ||
                selectedF24?.id_stato_extra > 0 ||
                selectedF24?.pagato == 1 ||
                !knowAcces(["admin", "f24", "team_leader", "paghe"])
              }
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Select
              type="text"
              label={"Iban"}
              name="id_iban"
              //onChange={(e) => handleChange(e)}
              value={selectedF24?.id_iban}
              onChange={(e) => handleChangeSelect(e, "id_iban", false)}
              placeholder="SELEZIONA..."
              options={elenco_iban.map((el) => {
                return { id: el.ID, name: el.iban + " - " + el.note };
              })}
              disabled={
                giornof24?.inibito == 1 ||
                !knowAcces(["admin", "f24", "team_leader", "paghe"])
              }
            />
          </div>

          <div className="col-span-2 md:col-span-1">
            <Select
              type="text"
              label={"Altri Stati F24"}
              name="id_stato_extra"
              value={selectedF24?.id_stato_extra}
              //onChange={(e) => handleChange(e)}
              //value={cliente.tipologia_servizio_assunzioni}
              onChange={(e) => handleChangeSelect(e, "id_stato_extra", true)}
              placeholder="SELEZIONA..."
              options={tipologia_f24}
              disabled={
                giornof24?.inibito == 1 ||
                !knowAcces(["admin", "f24", "team_leader", "paghe"])
              }
            />
          </div>

          <div className="col-span-2 md:col-span-1 pt-5">
            <CustomCheckbox
              label="pagamento parziale"
              name="pagamento_parziale"
              onClick={(e) => handleChangeCheckbox(e, "pagamento_parziale")}
              value={selectedF24?.pagamento_parziale === 1}
              disabled={
                giornof24?.inibito == 1 ||
                !knowAcces(["admin", "f24", "team_leader", "paghe"])
              }
            />
            <CustomCheckbox
              label="Versato"
              name="pagato"
              onClick={(e) => handleChangeCheckbox(e, "pagato")}
              value={selectedF24?.pagato === 1}
              disabled={
                giornof24?.inibito == 1 ||
                !knowAcces(["admin", "f24", "team_leader"])
              }
            />
            <CustomCheckbox
              label="quietanza Disponibile"
              name="quietanza_disponibile"
              onClick={(e) => handleChangeCheckbox(e, "quietanza_disponibile")}
              value={selectedF24?.quietanza_disponibile === 1}
              disabled={
                giornof24?.inibito == 1 ||
                !knowAcces(["admin", "f24", "team_leader", "segreteria"])
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
