import { useEffect, useRef, useState } from "react";
import { HiOutlineChevronRight, HiOutlineHome } from "react-icons/hi2";
import { useNavigate, useLocation } from "react-router-dom";

export const Breadcrumb = ({ link }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const previusLink = useRef(true);
  const pathMemory = useRef("");
  const [pathTot, setPathTot] = useState([]);

  const handleClick = (linkName, type, event) => {
    if (event) {
      if (
        event.target.id ===
        pathMemory.current[pathMemory.current.length - 1].props.id
      ) {
        return;
      } else {
        navigate(linkName);
      }
    } else {
      if (type === "back") {
        // se è una pagina dettaglio torno indietro
        navigate(linkName);
      } else {
        navigate(`${linkName}`);
      }
    }
  };

  useEffect(() => {
    pathMemory.current = pathTot;
  }, [pathTot]);

  useEffect(() => {
    (() => {
      let pathHistory = [...pathTot];

      // se eseguono un refresh forzo l'aggiunta di Home come primo elemento dell'array
      if (pathHistory.length === 0) {
        pathHistory.push(
          <div key={1} className="flex h-min">
            <a
              id="Home"
              className="breadcrumb-link group"
              onClick={() => handleClick("/Home")}
            >
              <HiOutlineHome className="text-slate-200 dark:text-white dark:group-hover:text-miller-500 text-lg mr-1" />
              Home
            </a>
          </div>
        );
      }

      for (let i = 0; i < pathHistory.length; i++) {
        // se non è "dettaglio" elimino lo storico dopo Home
        if (!pathname.includes("Dettaglio")) {
          pathHistory = pathHistory.slice(0, 1);
        }
        // se esiste già nella Breadcrumb torno indietro e elimino lo storico
        if (pathHistory[i].props.id === pathname) {
          pathHistory = pathHistory.slice(0, i + 1);
          previusLink.current = false;
        }
      }
      if (previusLink.current && pathname !== "/Home") {
        if (pathname.includes("Dettaglio")) {
          pathHistory.push(
            <div
              key={pathHistory.length + 1}
              className="flex h-min items-center"
            >
              <span className="mx-1 text-sm">{" > "}</span>
              <a
                id={pathname}
                className="breadcrumb-link"
                onClick={(e) => handleClick(-1, "back", e)}
              >
                {link.replace("/","")}
              </a>
            </div>
          );
        } else {
          pathHistory.push(
            <div
              key={pathHistory.length + 1}
              className="flex h-min items-center"
            >
              <span className="mx-1 text-sm">{" > "}</span>
              <a
                id={pathname}
                className="breadcrumb-link"
                onClick={() => handleClick(pathname)}
              >
                {pathname.replace("/","")}
              </a>
            </div>
          );
        }
      }
      setPathTot(pathHistory);
      previusLink.current = true;
    })();
  }, [pathname]);

  return (
    <div className="absolute top-0 md:top-14 w-full bg-miller-200 dark:bg-miller-500 text-slate-200 dark:text-white pt-12 mx:pt-4 pb-20 px-8 z-10">
      <h1 className="text-2xl font-medium pl-2">
        {pathname.includes("Dettaglio") ? link.replace("/","") : pathname.replace("/","")}
      </h1>
      <nav className="hidden md:block w-full rounded-md mt-2">
        <ol className="flex items-center z-50">{pathTot}</ol>
      </nav>
      <nav className="md:hidden block w-full rounded-md mt-2">
        <ol className="flex items-center z-50">
          <div className="flex h-min items-center breadcrumb-link">
            <span className="mx-1 text-sm">{" < "}</span>
            <a className="" onClick={() => navigate(-1)}>
              Indietro
            </a>
          </div>
        </ol>
      </nav>
    </div>
  );
};