import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { PrincipalButton } from "../../components/PrincipalButton";
import {
  HiOutlineCheck,
  HiOutlinePencil,
  HiOutlinePlus,
  HiOutlineTrash,
  HiOutlineXMark,
  HiPlus,
} from "react-icons/hi2";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import { CustomFooterPersonalized } from "../../components/DataGrid/CustomFooterPersonalized";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SlOptionsVertical } from "react-icons/sl";
import Modal from "../../components/Modal";
import api from "../../api/api";
import { Select } from "../../components/Select";
import { toast } from "react-toastify";
import { MdEdit } from "react-icons/md";
import { Input } from "../../components/Input";
import { CustomCheckbox } from "../../components/Checkbox";
import moment from "moment";
import { knowAcces } from "../../utils/utils";

export const SenzaIncarico = ({ screenSize, f24, dataF24 }) => {
  const [self24, setSelF24] = useState(null);
  const [referenti_esterni, setReferentiEsterni] = useState([]);
  const [elenco_iban, setElencoIban] = useState([]);
  const navigate = useNavigate();

  const anchorRef = useRef();
  const actionsButton = useRef();
  const [buttonVisible, setButtonVisible] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);

  const [elencoF24, setElencoF24] = useState([]);

  useEffect(() => {}, [screenSize]);

  const handleToggleButton = () => {
    setButtonVisible(!buttonVisible);
  };

  useEffect(() => {
    loadF24senzaIncarico();
  }, [f24]);

  const loadF24senzaIncarico = async () => {
    if (f24) {
      const responsef24 = await api.get(`/f24/senza_incarico/${f24}`, {
        params: { dataF24: dataF24 },
      });

      setElencoF24(responsef24.data);
    }
  };

  const loadModal = async (row) => {
    setSelF24(row);
    await loadReferenti(row);
    await loadIban(row);
    document.getElementById("dettaglioF24senzaincarico").click();
  };
  const loadReferenti = async (row) => {
    const refEst = await api.get(
      `/referenti_aziende_esterno/${row.codice_univoco}`
    );
    setReferentiEsterni(refEst.data.filter((el) => el.tipologia === 3));
  };
  const loadIban = async (row) => {
    const elIb = await api.get(`/iban/${row.codice_univoco}`);
    setElencoIban(elIb.data);
  };

  const handleSave = async (s) => {
    try {
      if (s.giorno_f24) {
        s.quietanza_disponibile = s.quietanza_disponibile == 1 ? 0 : 1;
        let update = await api.put("/f24", { f24: s });
      } else {
        s.quietanza_disponibile = 1;
        let insert = await api.post("/f24", { f24: s, idF24: f24 });
        //toast.success("f24 creato con successo")
      }
    } catch {
      toast.error("errore durante il salvataggio");
    }

    await loadF24senzaIncarico();
  };

  const columns = [
    {
      field: "inizio_incarico",
      headerName: "Data inizio incarico",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.inizio_incarico
          ? moment(params.row.inizio_incarico).format("DD/MM/YYYY")
          : "",
      valueGetter: (params) =>
        params.row.inizio_incarico
          ? moment(params.row.inizio_incarico).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "fine_incarico",
      headerName: "Data fine incarico",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.fine_incarico
          ? moment(params.row.fine_incarico).format("DD/MM/YYYY")
          : "",
      valueGetter: (params) =>
        params.row.fine_incarico
          ? moment(params.row.fine_incarico).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "codice_univoco",
      headerName: "Codice Univoco",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "codice_fiscale",
      headerName: "Codice Fiscale",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ragione_sociale",
      headerName: "Ragione Sociale",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "descrizione_payroll",
      headerName: "Referente",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cassetto_fiscale",
      headerName: "Scadenza Cassetto fiscale",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.cassetto_fiscale
          ? moment(params.row.cassetto_fiscale).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "quietanza_disponibile",
      headerName: "Quietanza disponibile",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row.quietanza_disponibile === 1 ? "SI" : "NO",
      renderCell: (params) => {
        return (
          <CustomCheckbox
            value={params.row.quietanza_disponibile === 1}
            onClick={() => handleSave(params.row)}
            disabled={!knowAcces(["admin", "f24", "segreteria", "team_leader"])}
          />
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <ModalF24
        tipologia={"inserimento"}
        idF24={f24}
        self24={self24}
        dataF24={dataF24}
        elenco_iban={elenco_iban}
        referenti_esterni={referenti_esterni}
        loadF24SenzaIncarico={loadF24senzaIncarico}
      />
      <div className="el-container h-[300px] md:h-[500px]">
        <DataGridPro
          density="compact"
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                inizio_incarico: false,
                fine_incarico: false,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
            loadingOverlay: LinearProgress,
            footer: CustomFooterPersonalized,
          }}
          //loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={elencoF24}
          getRowId={(r) => r.codice_univoco}
          //onRowClick={async (params)=>{ await loadModal(params.row)}}
        />
      </div>
    </>
  );
};

const ModalF24 = ({
  idF24,
  tipologia,
  self24,
  dataF24,
  elenco_iban,
  referenti_esterni,
  loadF24SenzaIncarico,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedF24, setSelectedF24] = useState(null);

  const handleClickOpen = async () => {
    console.log(referenti_esterni);
    let s = { ...self24 };

    if (!s.giorno_f24 && elenco_iban.length > 0) {
      s.id_iban = elenco_iban[0].ID;
    }

    setSelectedF24(s);
    setOpen(true);
  };

  const handleChangeCheckbox = (e, name) => {
    let sf24 = { ...selectedF24 };
    sf24[name] = e.target.checked ? 1 : 0;
    setSelectedF24(sf24);
  };

  const handleSave = async () => {
    try {
      if (selectedF24.giorno_f24) {
        let update = await api.put("/f24", { f24: selectedF24 });
        toast.success("f24 modificato con successo");
      } else {
        let insert = await api.post("/f24", { f24: selectedF24, idF24: idF24 });
        toast.success("f24 creato con successo");
      }
    } catch {
      toast.error("errore durante il salvataggio");
    }

    await loadF24SenzaIncarico();
  };

  return (
    <>
      <Button
        id="dettaglioF24senzaincarico"
        className="hidden"
        text="Aggiungi"
        type="button"
        icon={<HiPlus className="button-icon" />}
        onClick={async () => {
          await handleClickOpen();
        }}
      />

      <Modal
        isOpen={open}
        closeButton={() => setOpen(false)}
        headerText={self24?.ragione_sociale + " - " + dataF24}
        leftButtons={
          <Button
            className="principal-button z-[110]"
            text={tipologia === "elimina" ? "Elimina" : "Salva"}
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={() => handleSave()}
          />
        }
      >
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-2">
            <CustomCheckbox
              label="quietanza Disponibile"
              name="quietanza_disponibile"
              onClick={(e) => handleChangeCheckbox(e, "quietanza_disponibile")}
              value={selectedF24?.quietanza_disponibile === 1}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
