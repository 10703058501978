import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";
import { Input } from "../components/Input";
import logo from "../assets/images/logo-sm.png"
import api from "../api/api";
import { Button } from "../components/Button";
import { HiLockClosed } from "react-icons/hi";
import { HiOutlineCheck } from "react-icons/hi2";
import { toast } from "react-toastify";



export const Profilo = ({open,closeButton}) => {
    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [utente,setUtente] = useState(null);
    const [openModal,setOpenModal] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    
    
    const handleChange = (e) => {
       
    let u = { ...utente };
    u[e.target.name] = e.target.value;
    setUtente(u);
    }
  
useEffect(() =>{

if(open){
    loadProfileData()
}

},[open])


const loadProfileData= async() => {

const ut = await api.get(`/utenti/${sessionStorage.getItem("id")}`)
setUtente(ut.data[0]);
}


const changePassword = async(e) =>{
    if(password !==passwordConfirm && password !== ""){
        toast.error("Attenzione le password non corrispondono")
        return
    }else{
      
        let updateUser= await api.put("/utenti/password",{psw:password,id:sessionStorage.getItem("id")})
        toast.success("password modificata con successo")
    }


}

/*
const updateUser = async () => {

    let result = await api.put("/utenti",{user:utente});

    toast.success("modificato con successo")

} */



const handleImage = async (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function () {
      console.log(reader.result)


    let u = { ...utente };
    u.pic= reader.result;
    setUtente(u);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
};

return (
<>


<Modal isOpen={open} closeButton={closeButton} headerText="Profilo">


<div className="grid grid-cols-4 gap-2 ">
      
      <div className="col-span-4 content-center text-center">
     
      <img
          alt="user 5"
          src={utente?.pic?utente.pic:logo}
          onClick={() => document.getElementById("fileUploader").click()}
          className="relative inline-block h-48 w-48 rounded-full border-2 border-primary object-cover object-center hover:z-10 focus:z-10 hover:opacity-75 hover:cursor-pointer transition delay-150"
        />
    <input type="file" id="fileUploader" className="hidden" accept="image/png" onChange={(e) => handleImage(e)}/>

      </div>
   
      <div className="col-span-4 md:col-span-2">

      <Input
                type="text"
                label={"nome"}
                name="nome"
                onChange={(e) => handleChange(e)}
                value={utente?.nome}
                disabled={true}
              />

      </div>
      <div className="col-span-4 md:col-span-2">

<Input
          type="text"
          label={"username"}
          name="username"
          onChange={(e) => handleChange(e)}
          value={utente?.username}
          disabled={true}
        />

</div>
      
      <div className="col-span-4 md:col-span-2">

      <Input
                type="text"
                label={"email"}
                name="email"
                onChange={(e) => handleChange(e)}
                value={utente?.email}
                disabled={true}
              />

      </div>
      <div className="col-span-4 md:col-span-2">

      <Button
                className="success-button mt-6"
                text="Cambia Password"
                type="button"
                icon={<HiLockClosed className="button-icon" />}
                onClick={async () => {
                    setOpenModal(true)
                }}
              />

</div>


      </div>


</Modal>
<Modal isOpen={openModal} closeButton={() => setOpenModal(false)} headerText="Modifica Password" size="small"
leftButtons={<Button
    className="principal-button z-[110]"
    text={"Salva"}
    type="button"
    icon={<HiOutlineCheck className="button-icon" />}
    onClick={async () => {
     changePassword()
    }}
  />}

>
<div className="grid grid-cols-1 gap-2 ">
                  <div className="relative">
                    <span className="absolute right-0 flex items-center pr-2 h-full">
                      <button
                        type="button"
                        onClick={() => setShow(!show)}
                        className="p-1 focus:outline-none focus:shadow-outline"
                      >
                        <svg
                          hidden={!show}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                          <path
                            fillRule="evenodd"
                            d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <svg
                          hidden={show}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                          <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                          <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                        </svg>
                      </button>
                    </span>
                    <input
                      type={show ? "text" : "password"}
                      name="password"
                      id="password"
                      className="w-full py-4 px-8 rounded-md bg-white ring-1 dark:ring-miller-300"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
      <div >

      <div className="relative">
                    <span className="absolute right-0 flex items-center pr-2 h-full">
                      <button
                        type="button"
                        onClick={() => setShowConfirm(!showConfirm)}
                        className="p-1 focus:outline-none focus:shadow-outline"
                      >
                        <svg
                          hidden={!showConfirm}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                          <path
                            fillRule="evenodd"
                            d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <svg
                          hidden={showConfirm}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                          <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                          <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                        </svg>
                      </button>
                    </span>
                
                    <input
                      type={showConfirm ? "text" : "password"}
                      name="password"
                      id="password"
                      className="w-full py-4 px-8 rounded-md bg-white ring-1 dark:ring-miller-300"
                      placeholder="Conferma Password"
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      value={passwordConfirm}
                    />
                  </div>

</div>
 </div>
</Modal>
</>
)

}

