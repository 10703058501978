//Modal bug on Home not fully covering left side 20 px off to replicate : Home => Mouse over user icon => click on sincronizza dati

import React, { useEffect, useState, useRef } from "react";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { HiOutlineXMark } from "react-icons/hi2";
const Modal = ({
  headerText,
  size,
  children,
  isOpen,
  closeButton,
  leftButtons,
  rightButtons,
  collapsed,
  screenSize,
}) => {
  const modalOverlay = useRef();
  const modal = useRef();
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("sidebar") === "collapsed") {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  }, [sessionStorage.getItem("sidebar")]);

  useEffect(() => {
    if (isOpen) {
      modalOverlay.current.classList.remove("hidden");
      modal.current.classList.remove("opacity-0");
      modal.current.classList.remove("-translate-y-full");
    }
  }, [isOpen]);
  return (
    <>
      {isOpen && (
        <div
          ref={modalOverlay}
          id="overlayModal"
          className="fixed inset-0 z-[120] w-full h-full bg-gray-600 bg-opacity-50"
          onClick={() => closeButton()}
        >
          <div
            className={`transform  relative w-full h-full mx-auto my-auto flex items-center justify-center `}
          >
            <div
              id="modal"
              ref={modal}
              onClick={(e) => e.stopPropagation()}
              className={`max-h-[90%] flex flex-col absolute opacity-0 -translate-y-full z-[70] transform relative bg-white rounded-md shadow transition-opacity duration-400 max-w-sm md:max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl min-w-sm md:min-w-screen-md lg:min-w-screen-lg 2xl:min-w-screen-xl my-auto border border-slate-200 ${
                size === "small"
                  ? "w-1/3"
                  : size === "medium"
                  ? "w-1/2"
                  : "w-full"
              }`}
            >
              <div className="flex items-start justify-center p-4 border-b rounded-t relative flex-wrap">
                <h3 className="text-lg md:text-2xl font-medium text-gray-900 capitalize">
                  {headerText}
                </h3>
                <div
                  type="button"
                  className="absolute -right-4 -top-4 text-gray-400 bg-white border-2 border-gray-300 rounded-full text-sm p-1.5 ml-auto inline-flex items-center cursor-pointer transition-all duration-200 hover:scale-110 hover:bg-gray-300 hover:text-white"
                  data-modal-hide="staticModal"
                  onClick={() => closeButton()}
                >
                  <HiOutlineXMark className="button-icon" />
                </div>
              </div>
              <div className="p-3 overflow-auto">{children}</div>
              <div className="flex items-center justify-between py-3 px-4 space-x-4 border-t border-slate-200 rounded-b">
                <div className="flex flex-nowrap space-x-2">{rightButtons}</div>
                <div className="flex flex-nowrap space-x-2">{leftButtons}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
