import React, { useState, useRef, useEffect } from "react";

import { Grid, ButtonGroup, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridFooterContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  useGridApiContext,
  gridFilteredSortedRowEntriesSelector,
  gridVisibleColumnFieldsSelector,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
} from "@mui/x-data-grid-pro";
import { Button } from "../Button";

import Grow from "@mui/material/Grow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { SlOptionsVertical } from "react-icons/sl";
import { HiOutlineTableCells } from "react-icons/hi2";

export const CustomFooterPersonalized = ({ fileName }) => {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const setDimension = () => {
    getDimension(window.innerWidth);
  };
  const anchorRef = useRef(null);
  const [openButton, setOpenButton] = useState(false);

  const handleToggleButton = () => {
    setOpenButton(!openButton);
  };
  const handleCloseButton = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenButton(false);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
  }, [screenSize]);
  return (
    <GridFooterContainer
      sx={{
        display: "flex",
        justifyContent: { xs: "center", md: "flex-start" },
        px: 2,
        flexWrap: { xs: "wrap", md: "nowrap" },
      }}
    >
      {screenSize > "768" ? (
        <>
          <GridToolbarColumnsButton
            id="GridFooterColumnsButton"
            style={{
              color: "#2265B1",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: 0,
            }}
          />
          <GridToolbarFilterButton
            id="GridFooterColumnsButton"
            style={{
              color: "#2265B1",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: 0,
            }}
          />
          <GridToolbarDensitySelector
            id="GridFooterColumnsButton"
            style={{
              color: "#2265B1",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: 0,
            }}
          />
          <GridToolbarExportContainer
            id="GridFooterColumnsButton"
            style={{
              color: "#2265B1",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: 0,
            }}
          >
            <ExcelExportMenuItem csvFileName={fileName} />
            <GridCsvExportMenuItem
              options={{ fileName: fileName ? fileName : "Export" }}
            />
            <GridPrintExportMenuItem />
          </GridToolbarExportContainer>
          {/*   <GridToolbarExport
            id="GridFooterColumnsButton"
            csvOptions={{
              fileName: csvFileName?csvFileName:"export",
           
            }}
          
            style={{
              color: "#2265B1",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: 0,
            }}
          />*/}
        </>
      ) : (
        <>
          <ButtonGroup
            aria-label="split button"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            ref={anchorRef}
          >
            <Button
              className="success-button items-end"
              text="Opzioni tabella"
              type="button"
              icon={<HiOutlineTableCells className="button-icon mr-2" />}
              onClick={async () => {
                handleToggleButton();
              }}
            />
            <Popper
              sx={{
                zIndex: 100,
              }}
              open={openButton}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}>
                  <Paper sx={{ width: "181px" }}>
                    <ClickAwayListener onClickAway={handleCloseButton}>
                      <MenuList id="split-button-menu" Item sx={{ p: 0 }}>
                        <MenuItem
                          key={1}
                          onClick={handleToggleButton}
                          sx={{ p: 0 }}
                        >
                          <GridToolbarColumnsButton
                            style={{
                              color: "#2265B1",
                              border: 0,
                              width: "100%",
                              padding: "10px 32px 10px 20px ",
                              justifyContent: "flex-start",
                            }}
                          />
                        </MenuItem>
                        <MenuItem
                          key={0}
                          onClick={handleToggleButton}
                          sx={{ p: 0 }}
                        >
                          <GridToolbarFilterButton
                            style={{
                              color: "#2265B1",
                              border: 0,
                              width: "100%",
                              padding: "10px 32px 10px 15px ",

                              justifyContent: "flex-start",
                            }}
                            fullWidth
                          />
                        </MenuItem>

                        <MenuItem key={2} sx={{ p: 0 }}>
                          <GridToolbarDensitySelector
                            style={{
                              color: "#2265B1",
                              border: 0,
                              width: "100%",
                              padding: "10px 32px 10px 18px ",

                              justifyContent: "flex-start",
                            }}
                          />
                        </MenuItem>
                        <MenuItem key={3} sx={{ p: 0 }}>
                          <GridToolbarExport
                            csvOptions={{
                              fileName: fileName ? fileName : "export",
                            }}
                            style={{
                              color: "#2265B1",
                              border: 0,
                              width: "100%",
                              padding: "10px 32px 10px 18px ",
                              justifyContent: "flex-start",
                            }}
                          />
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ButtonGroup>
        </>
      )}
      {/*  */}
    </GridFooterContainer>
  );
};

const ExcelExportMenuItem = (props) => {
  const apiRef = useGridApiContext();
  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        console.log(apiRef);
        const xlsxBlob = getXlsx(apiRef);

        // Function to save the file
        saveAs(
          xlsxBlob,
          (props.csvFileName ? props.csvFileName : "Export") + ".xlsx"
        );

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export Xlsx
    </MenuItem>
  );
};

const getXlsx = (apiRef) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnDefinitionsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((el) => {
      if (el.field !== "Azioni" && el.field !== "pic") {
        row[el.headerName] = apiRef.current.getCellParams(id, el.field).value;
      }
    });
    return row;
  });

  // Create a new workbook and add the data
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Write the workbook to a binary string
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  // Convert the binary string to a Blob
  const buf = new ArrayBuffer(wbout.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < wbout.length; i++) {
    view[i] = wbout.charCodeAt(i) & 0xff;
  }
  return new Blob([buf], { type: "application/octet-stream" });
};

// Helper function to save the blob as a file
const saveAs = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};
