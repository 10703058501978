import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider,createBrowserRouter } from 'react-router-dom';
import { Home } from "./pages/Home";
import Login from "./pages/Login";

import { Clienti } from "./pages/Clienti";
import { F24 } from "./pages/F24";
import { aziendeLoader } from './loaders/aziende_loaders';
import { DettaglioClienti } from "./pages/Clienti/DettaglioClienti";
import { AppLayout } from './pages/AppLayout';
import RequireAuth from './components/RequireAuth';


const router = createBrowserRouter([
  {
    path: "/Login",
    element: <Login  />,

  },
  {
    path: "/",
    element: <RequireAuth /> ,
    children:[
      {
        path: "/",
        element: <AppLayout  />,

      },
      {
        path: "/Clienti",
        element: <Clienti  />,
        loader:aziendeLoader
      },
      {
        path: "/Home",
        element: <Home  />,
      },
      {
        path: "/DettaglioClienti",
        element: <DettaglioClienti  />,
      },
      {
        path: "/F24",
        element: <F24 />,
      },
    ]
  },
 
])



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App/>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
