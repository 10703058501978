import { useEffect, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { MdEdit, MdDelete, MdAdd, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import LinearProgress from '@mui/material/LinearProgress';
import {
    DataGridPro,
    itIT,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { Select } from '../../components/Select';
import { Input } from '../../components/Input';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment';
import { CustomFooterPersonalized } from '../../components/DataGrid/CustomFooterPersonalized';
import { SlOptionsVertical } from 'react-icons/sl';
import Modal from '../../components/Modal';
import {
    HiMiniArrowDownTray,
    HiOutlineCheck,
    HiOutlinePlus,
    HiOutlineTrash,
    HiOutlineXMark,
    HiPlus,
} from 'react-icons/hi2';
import { PrincipalButton } from '../../components/PrincipalButton';
import api from '../../api/api';
import { CustomCheckbox } from '../../components/Checkbox';




export const TipologiaServizioAssunzioni = ({ screenSize }) => {


    const [liste, setListe] = useState([]);
    const [listaSel, setListaSel] = useState(null);
    const [tipologia,setTipologia] = useState("")

    const columns = [
        {
            field: 'Azioni',
            headerName: 'Azioni',

            headerAlign: 'center',
            align: 'center',
            filterable: false,
            renderCell: (params) => {
                return (<><Button
                    className='edit-button '
                    type='button'
                    icon={<MdEdit className='button-icon' />}
                    onClick={async () => {
                        setTipologia("modifica")
                        setListaSel(params.row)

                    }
                    }
                />
                <Button
                className='delete-button '
                type='button'
                icon={<MdDelete className='button-icon' />}
                onClick={async () => {
                    setTipologia("elimina")
                    setListaSel(params.row)

                }
                }
            />
                
                </>
                )

            }

        },
        {
            field: "name",
            headerName: "Nome",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },


    ];
    useEffect(() => { }, [screenSize]);

    const loadListe = async () => {
        const prest = await api.get("/tipologia_servizio_assunzioni");
        setListe(prest.data)
    }





    useEffect(() => { //carico clienti subito
        loadListe()
    
        //
    }, []);

    useEffect(() => {
        if (listaSel && tipologia=="modifica") {
            document.getElementById("editServizioAssunzioni").click()
        }
        if (listaSel && tipologia=="elimina") {
            document.getElementById("deleteServizioAssunzioni").click()
        }
console.log("pino",listaSel,tipologia)
    }, [listaSel,tipologia])


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div className='w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1'>
                    <div className='flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2'>
                        <GridToolbarQuickFilter placeholder='Cerca...' />
                    </div>
                    <div className='flex justify-center md:justify-end md:mx-0 mx-auto'>

                        <ModalPrestazione tipologia={"inserimento"} loadListe={loadListe} />
                    </div>
                </div>
            </GridToolbarContainer>
        );
    }



    return (


        <div className="el-container h-fit ">

            <ModalPrestazione tipologia={"modifica"}  listaSel={listaSel} loadListe={loadListe}/>
            <ModalPrestazione tipologia={"elimina"}  listaSel={listaSel} loadListe={loadListe}/>

            <div className='w-full md:h-[70vh] h-[70vh] relative el-container'>

                <DataGridPro
density='compact'
                    slots={{
                        toolbar: CustomToolbar,
                        loadingOverlay: LinearProgress,
                        footer: CustomFooterPersonalized,
                    }}
                    //loading={loading}
                    localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                    rows={liste}
                    getRowId={(r) => r.id}
                />
            </div>




        </div>
    )

}



const ModalPrestazione = ({ tipologia, listaSel, loadListe}) => {
    const [open, setOpen] = useState(false);
    const [lista, setLista] = useState(null)

        
    const handleOpenModal = () => {

        if (tipologia === "modifica" || tipologia==="elimina") {
            console.log(listaSel)
            setLista(listaSel)
      
        }else{

            setLista({
               name:""


            })

        }
        setOpen(true)


    }
    const handleChange = (e) => {

        let l = { ...lista };
       l[e.target.name] = e.target.value;
        setLista(l);


    }
    const handleSave = async () => {

        try {

            if (tipologia === "inserimento") {
            
                    await api.post("/tipologia_servizio_assunzioni", { servizio: lista })
               
                    toast.success("Servizio inserito con successo")

            } else if(tipologia =="modifica"){
                await api.put("/tipologia_servizio_assunzioni", { servizio: lista })
                toast.success("Servizio modificato con successo")
            }else {

                await api.delete("/tipologia_servizio_assunzioni/"+lista.id)
                toast.success("Servizio eliminato con successo")

            }
            loadListe();
            setOpen(false)
        } catch {


            toast.error("Errore durante il salvataggio")
        }

    }

    return (<>
        {tipologia === "inserimento" ?
            <Button
                className="success-button"
                text="Aggiungi"
                type="button"
                icon={<HiPlus className="button-icon" />}
                onClick={async () => {
                    handleOpenModal()

                }}
            /> :
tipologia=="modifica"?
            <Button
                className='hidden'
                id="editServizioAssunzioni"
                type='button'
                icon={<MdEdit className='button-icon ' />}
                onClick={async () => {
                    handleOpenModal()
                }}
            />
:
            <Button
                className='hidden'
                id="deleteServizioAssunzioni"
                type='button'
                icon={<MdEdit className='button-icon ' />}
                onClick={async () => {
                    handleOpenModal()
                }}
            />

        }
        <Modal isOpen={open} closeButton={() => setOpen(false)} headerText={`${tipologia} servizio`} size={"medium"} leftButtons={<Button
            className="principal-button"
            text="Salva"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
                handleSave()

            }}
        />}>

            {
            tipologia =="elimina"?
            <>Sei sicuro di voler eliminare il servizio {lista?.name}</>
            
            :
            <div className='grid grid-cols-2 gap-2'>
                <div className='col-span-2 '>
                    <Input
                        type="text"
                        label={"descrizione"}
                        name="name"
                        onChange={(e) => handleChange(e)}
                        value={lista?.name}
    
                    />
                </div>
               
             
              
            </div>
            }
        </Modal>


    </>)



}