import { useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { CustomCheckbox } from "../../components/Checkbox";
import { Select } from "../../components/Select";
import { PrincipalButton } from "../../components/PrincipalButton";
import { Button } from "../../components/Button";
import { HiOutlineCheck, HiOutlineTrash, HiPlus } from "react-icons/hi2";
import LinearProgress from "@mui/material/LinearProgress";
import { CustomFooterPersonalized } from "../../components/DataGrid/CustomFooterPersonalized";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import Modal from "../../components/Modal";
import api from "../../api/api";
import { toast } from "react-toastify";
import { MdEdit, MdCheckCircle, MdCircle } from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import { knowAcces } from "../../utils/utils";

export const RecapitiClienti = ({
  cliente,
  handleChange,
  handleSave,
  handleChangeSelect,
  handleChangeCheckbox,
}) => {
  const [tipologia_referente_esterno, setTipologiaReferenteEsterno] = useState(
    []
  );
  const [referenti_esterni, setReferentiEsterni] = useState([]);
  const [referente, setReferente] = useState(null);
  const [iban, setIban] = useState(null);
  const [elenco_iban, setElencoIban] = useState([]);
  const [tipologia_altro, setTipologiaAltro] = useState([]);
  const [liste, setListe] = useState([]);
  const [accounting, setAccounting] = useState([]);

  const loadData = async () => {
    try {
      const tipologia = await api.get(`/tipologia_referente_esterno`);
      setTipologiaReferenteEsterno(tipologia.data);
      const tipologia_altro = await api.get(`/tipologia_servizio_altro`);
      setTipologiaAltro(tipologia_altro.data);
      loadReferenti();
      loadIban();
      loadListe();
      loadReferentiAccounting();
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };

  const loadReferenti = async () => {
    const refEst = await api.get(
      `/referenti_aziende_esterno/${cliente.codice_univoco}`
    );
    setReferentiEsterni(refEst.data);
  };
  const loadIban = async () => {
    const elIb = await api.get(`/iban/${cliente.codice_univoco}`);
    setElencoIban(elIb.data);
  };

  const loadListe = async () => {
    const elIb = await api.get(`/liste`);
    setListe(elIb.data);
  };

  const loadReferentiAccounting = async () => {
    const prest = await api.get("/referenti_accounting");
    setAccounting(prest.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  const columns = [
    {
      field: "Azioni",
      headerName: "Azioni",

      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div className="hidden md:flex flex-nowrap gap-2">
              <Button
                className="edit-button "
                id="editEmail"
                type="button"
                icon={<MdEdit className="button-icon" />}
                onClick={async () => {
                  await setReferente(params.row);

                  document.getElementById("editEmail").click();
                }}
              />

              <Button
                className="delete-button"
                type="button"
                icon={<HiOutlineTrash className="button-icon" />}
                onClick={async () => {
                  await setReferente(params.row);

                  document.getElementById("deleteEmail").click();
                }}
              />
            </div>
          </>
        );
      },
    },

    {
      field: "referente",
      headerName: "Referente",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email_referente",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "responsabile_payroll",
      headerName: "Payroll",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.responsabile_payroll === 1 ? (
          <MdCheckCircle className="button-icon text-green-400" />
        ) : (
          <MdCircle className="button-icon text-gray-400" />
        ),
    },
    {
      field: "responsabile_assunzioni",
      headerName: "Assunzioni",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.responsabile_assunzioni === 1 ? (
          <MdCheckCircle className="button-icon text-green-400" />
        ) : (
          <MdCircle className="button-icon text-gray-400" />
        ),
    },
    {
      field: "quietanza_f24",
      headerName: "Quietanza F24",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.quietanza_f24 === 1 ? (
          <MdCheckCircle className="button-icon text-green-400" />
        ) : (
          <MdCircle className="button-icon text-gray-400" />
        ),
    },
    {
      field: "dm10",
      headerName: "DM 10",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.dm10 === 1 ? (
          <MdCheckCircle className="button-icon text-green-400" />
        ) : (
          <MdCircle className="button-icon text-gray-400" />
        ),
    },
    {
      field: "Newsletter",
      headerName: "newsletter",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.newsletter === 1 ? (
          <MdCheckCircle className="button-icon text-green-400" />
        ) : (
          <MdCircle className="button-icon text-gray-400" />
        ),
    },
    {
      field: "pw_dichiarativi_criptati",
      headerName: "Invio PW dichiarativi criptati",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.pw_dichiarativi_criptati === 1 ? (
          <MdCheckCircle className="button-icon text-green-400" />
        ) : (
          <MdCircle className="button-icon text-gray-400" />
        ),
    },
  ];

  const columnsIban = [
    {
      field: "Azioni",
      headerName: "Azioni",

      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div className="hidden md:flex flex-nowrap gap-2">
              <Button
                className="edit-button "
                id="editEmail"
                type="button"
                icon={<MdEdit className="button-icon" />}
                onClick={async () => {
                  await setIban(params.row);

                  document.getElementById("editIban").click();
                }}
              />

              <Button
                className="delete-button"
                type="button"
                icon={<HiOutlineTrash className="button-icon" />}
                onClick={async () => {
                  await setIban(params.row);

                  document.getElementById("deleteIban").click();
                }}
              />
            </div>
          </>
        );
      },
    },

    {
      field: "iban",
      headerName: "IBAN",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "note",
      headerName: "Note",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleExportReferentiEsterno = async () => {
    try {
      let response = await api.post(
        `/exports/referenti_esterno`,
        {},
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "" })
      ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "referenti.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.success("donwnload eseguito con successo");
    } catch {
      toast.error("errore nel download dei documenti");
    }
  };

  const handleExportIban = async () => {
    try {
      let response = await api.post(
        `/exports/iban_aziende`,
        {},
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "" })
      ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "iban.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.success("donwnload eseguito con successo");
    } catch {
      toast.error("errore nel download dei documenti");
    }
  };

  function CustomToolbarContattiEmail() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <div className="flex flex-row-reverse">
            <ModalEmail
              tipologia={"inserimento"}
              liste={liste}
              tipologia_referente_esterno={tipologia_referente_esterno}
              cliente={cliente.codice_univoco}
              dettaglio={{ referente: "", email_referente: "", tipologia: "" }}
              loadReferenti={loadReferenti}
            />
            <Button
              className="principal-button z-[110]"
              text={"Esportazione massiva"}
              type="button"
              icon={<HiDownload className="button-icon" />}
              onClick={handleExportReferentiEsterno}
            />
          </div>
          <ModalEmail
            tipologia={"modifica"}
            liste={liste}
            tipologia_referente_esterno={tipologia_referente_esterno}
            cliente={cliente.codice_univoco}
            dettaglio={referente}
            loadReferenti={loadReferenti}
          />
          <ModalEmail
            tipologia={"elimina"}
            liste={liste}
            tipologia_referente_esterno={tipologia_referente_esterno}
            cliente={cliente.codice_univoco}
            dettaglio={referente}
            loadReferenti={loadReferenti}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  function CustomToolbarIban() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <div className="flex flex-row-reverse">
            <ModalIban
              tipologia={"inserimento"}
              cliente={cliente.codice_univoco}
              dettaglio={{ referente: "", email_referente: "", tipologia: "" }}
              loadIban={loadIban}
            />
            <Button
              className="principal-button z-[110]"
              text={"Esportazione massiva"}
              type="button"
              icon={<HiDownload className="button-icon" />}
              onClick={handleExportIban}
            />
          </div>

          <ModalIban
            tipologia={"modifica"}
            cliente={cliente.codice_univoco}
            dettaglio={iban}
            loadIban={loadIban}
          />
          <ModalIban
            tipologia={"elimina"}
            cliente={cliente.codice_univoco}
            dettaglio={iban}
            loadIban={loadIban}
          />
        </div>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <PrincipalButton
        button={
          <Button
            className="principal-button z-[110]"
            text="Salva"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSave();
            }}
          />
        }
      />
      <div className="grid grid-cols-2 gap-2 w-full el-container">
        <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"PEC"}
            name="pec"
            onChange={(e) => handleChange(e)}
            value={cliente.pec}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="date"
            label={"Cassetto fiscale (Data di scadenza)"}
            name="cassetto_fiscale"
            onChange={(e) => handleChange(e)}
            value={
              cliente.cassetto_fiscale
                ? moment(cliente.cassetto_fiscale).format("YYYY-MM-DD")
                : null
            }
            disabled={!knowAcces(["admin", "team_leader", "f24", "segreteria"])}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="date"
            label={"data inizio incarico (F24)"}
            name="inizio_incarico"
            onChange={(e) => handleChange(e)}
            value={
              cliente.inizio_incarico
                ? moment(cliente.inizio_incarico).format("YYYY-MM-DD")
                : null
            }
            disabled={!knowAcces(["admin", "team_leader", "f24"])}
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <Input
            type="date"
            label={"data fine incarico (F24)"}
            name="fine_incarico"
            onChange={(e) => handleChange(e)}
            value={
              cliente.fine_incarico
                ? moment(cliente.fine_incarico).format("YYYY-MM-DD")
                : null
            }
            disabled={!knowAcces(["admin", "team_leader", "f24"])}
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"Commercialista"}
            name="descrizione_commercialista"
            onChange={(e) => handleChange(e)}
            value={cliente.descrizione_commercialista}
            disabled={true}
          />
        </div>
        {cliente.altro_commercialista === "000001" && (
          <div className="col-span-2 md:col-span-1">
            <Select
              type="text"
              label={"referente Accounting"}
              name="referente_miller_accounting"
              onChange={(e) =>
                handleChangeSelect(e, "referente_miller_accounting")
              }
              value={cliente.referente_miller_accounting}
              placeholder="Seleziona..."
              options={accounting}
              disabled={false}
            />
          </div>
        )}
        {cliente.descrizione_commercialista === "Miller TAX" && (
          <div className="col-span-2 md:col-span-1">
            <Select
              type="text"
              label={"tipologia servizio accounting"}
              name="tipologia_servizio_altro"
              onChange={(e) =>
                handleChangeSelect(e, "tipologia_servizio_altro")
              }
              value={cliente.tipologia_servizio_altro}
              placeholder="Seleziona..."
              options={tipologia_altro}
              disabled={!knowAcces(["admin", "team_leader"])}
            />
          </div>
        )}

        <div className="col-span-2  self-center">
          <CustomCheckbox
            label="IBAN diego (F24)"
            name="iban_diego_f24"
            checked={cliente.iban_diego_f24}
            onClick={(e) => handleChangeCheckbox(e, "iban_diego_f24")}
            //onClick={(e) => filter(e, "assegnati")}
            value={cliente.iban_diego_f24}
            disabled={!knowAcces(["admin", "team_leader", "f24"])}
          />
          <CustomCheckbox
            label="invio Mensile (DM10)"
            name="invio_mensile"
            checked={cliente.invio_mensile}
            onClick={(e) => handleChangeCheckbox(e, "invio_mensile")}
            //onClick={(e) => filter(e, "assegnati")}
            value={cliente.invio_mensile}
            disabled={!knowAcces(["admin", "team_leader", "f24", "paghe"])}
          />

          <CustomCheckbox
            label="cliente Miller Sicurezza"
            name="cliente_miller_sicurezza"
            checked={cliente.cliente_miller_sicurezza === "S"}
            //onClick={(e) => filter(e, "assegnati")}
            value={cliente.cliente_miller_sicurezza === "S"}
            disabled={true}
          />
          {/*} <CustomCheckbox
            label="cliente Miller Accounting"
            name="clienteMillerAccounting"
            checked={cliente.altro_commercialista === "000001"}
            //onClick={(e) => filter(e, "assegnati")}
            value={cliente.altro_commercialista === "000001"}
            disabled={true}
          /> */}
        </div>
      </div>

      <div className="col-span-2 grid grid-cols-2 gap-2 el-container mt-2">
        <div className="col-span-2 md:col-span-2 h-[40vh]">
          Referenti & email
          <DataGridPro
            density="compact"
            slots={{
              toolbar: CustomToolbarContattiEmail,
              loadingOverlay: LinearProgress,
              footer: CustomFooterPersonalized,
            }}
            //loading={loading}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            rows={referenti_esterni}
            getRowId={(r) => r.ID}
          />
        </div>
      </div>

      {!(cliente.iban_diego_f24 == 1) &&
        knowAcces(["admin", "team_leader", "f24"]) && (
          <div className="col-span-2 grid grid-cols-2 gap-2 relative dark:bg-miller-300 bg-white p-2 rounded-md max-h-full min-h-full  overflow-y-hidden mt-2 ">
            <div className="col-span-2 md:col-span-2 h-[40vh]">
              Iban
              <DataGridPro
                density="compact"
                slots={{
                  toolbar: CustomToolbarIban,
                  loadingOverlay: LinearProgress,
                  footer: CustomFooterPersonalized,
                }}
                //loading={loading}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                columns={columnsIban}
                rows={elenco_iban}
                getRowId={(r) => r.ID}
              />
            </div>
          </div>
        )}
    </>
  );
};

const ModalEmail = ({
  tipologia,
  tipologia_referente_esterno,
  cliente,
  dettaglio,
  loadReferenti,
  liste,
}) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dettaglioEmail, setDettaglioEmail] = useState({
    referente: "",
    email_referente: "",
    tipologia: "",
  });
  const [listeAssociate, setListeAssociate] = useState([]);
  const [listeDaRimuove, setListeDaRimuovere] = useState([]);
  const [listaSel, setListaSel] = useState(null);

  const handleChange = (e) => {
    let d = { ...dettaglioEmail };
    d[e.target.name] = e.target.value;
    setDettaglioEmail(d);
  };

  const handleChangeCheckbox = (e, name) => {
    let d = { ...dettaglioEmail };
    d[name] = e.target.checked ? 1 : 0;
    setDettaglioEmail(d);
  };

  const handleClickOpen = async () => {
    await loadListeAssociate();

    setDettaglioEmail(dettaglio);
    setOpen(true);
  };

  const loadListeAssociate = async () => {
    try {
      let resp = await api.get(
        `/referenti_per_lista/referente/${dettaglio.ID}`
      );
      setListeAssociate(resp.data);
      setListeDaRimuovere(resp.data.map((el) => el.id));
    } catch {
      toast.error("Errore durante il caricamente");
    }
  };

  const handleSave = async () => {
    try {
      if (tipologia === "inserimento") {
        let savePayroll = await api.post("/referenti_aziende_esterno", {
          referente: dettaglioEmail,
          cliente: cliente,
        });

        toast.success("salvato con successo");
      }

      if (tipologia === "modifica") {
        let savePayroll = await api.put("/referenti_aziende_esterno", {
          referente: dettaglioEmail,
        });

        toast.success("salvato con successo");
      }

      if (tipologia === "elimina") {
        let savePayroll = await api.delete(
          `/referenti_aziende_esterno/${dettaglioEmail.ID}`
        );

        toast.success("eliminato con successo");
      }

      loadReferenti();
    } catch {
      toast.error("errore durante il salvataggio");
    }
  };

  const handleAssocia = async () => {
    try {
      let savePayroll = await api.post("/referenti_per_lista", {
        referente: dettaglioEmail.ID,
        lista: listaSel,
      });

      toast.success("associato con successo");

      loadListeAssociate();
    } catch {
      toast.error("errore durante il salvataggio");
    }
  };
  const removeFromLista = async (id) => {
    try {
      let savePayroll = await api.delete(`/referenti_per_lista/${id}`);

      toast.success("rimosso con successo");

      loadListeAssociate();
    } catch {
      toast.error("errore durante il salvataggio");
    }
  };

  const columns = [
    {
      field: "Azioni",
      headerName: "Azioni",

      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div className="hidden md:flex flex-nowrap gap-2">
              <Button
                className="delete-button"
                type="button"
                icon={<HiOutlineTrash className="button-icon" />}
                onClick={async () => {
                  removeFromLista(params.row.id);
                }}
              />
            </div>
          </>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <Button
            className="principal-button "
            text="Aggiungi"
            type="button"
            icon={<HiPlus className="button-icon" />}
            onClick={() => {
              setListaSel(true);
              setOpenModal(true);
            }}
          />
        </div>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      {tipologia === "inserimento" ? (
        <Button
          className="principal-button "
          text="Aggiungi"
          type="button"
          icon={<HiPlus className="button-icon" />}
          onClick={async () => {
            setOpen(true);
          }}
        />
      ) : tipologia === "modifica" ? (
        <Button
          className=" hidden"
          id="editEmail"
          type="button"
          icon={<MdEdit className="button-icon" />}
          onClick={async () => {
            handleClickOpen();
          }}
        />
      ) : (
        <Button
          className=" hidden"
          id="deleteEmail"
          type="button"
          icon={<MdEdit className="button-icon" />}
          onClick={async () => {
            handleClickOpen();
          }}
        />
      )}
      <Modal
        isOpen={open}
        closeButton={() => setOpen(false)}
        headerText={tipologia + ` Referente`}
        leftButtons={
          <Button
            className="principal-button z-[110]"
            text={tipologia === "elimina" ? "Elimina" : "Salva"}
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSave();
            }}
          />
        }
      >
        <div className="grid grid-cols-2 gap-2">
          {tipologia === "elimina" ? (
            <div className="col-span-2 ">
              Attenzione, sei sicuro di voler eliminare:
              <span className="font-bold">
                {" " +
                  dettaglioEmail.referente +
                  " - " +
                  dettaglioEmail.email_referente}
              </span>
            </div>
          ) : (
            <>
              <div className="col-span-2 md:col-span-1">
                <Input
                  type="text"
                  label={"referente"}
                  name="referente"
                  onChange={(e) => handleChange(e)}
                  value={dettaglioEmail.referente}
                  disabled={false}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <Input
                  type="text"
                  label={"Email referente"}
                  name="email_referente"
                  onChange={(e) => handleChange(e)}
                  value={dettaglioEmail.email_referente}
                  disabled={false}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <CustomCheckbox
                  label="Responsabile Payroll"
                  name="responsabile_payroll"
                  checked={dettaglioEmail.responsabile_payroll}
                  onClick={(e) =>
                    handleChangeCheckbox(e, "responsabile_payroll")
                  }
                  value={dettaglioEmail.responsabile_payroll}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <CustomCheckbox
                  label="Responsabile Assunzioni"
                  name="responsabile_assunzioni"
                  checked={dettaglioEmail.responsabile_assunzioni}
                  onClick={(e) =>
                    handleChangeCheckbox(e, "responsabile_assunzioni")
                  }
                  value={dettaglioEmail.responsabile_assunzioni}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <CustomCheckbox
                  label="Quietanza F24"
                  name="quietanza_f24"
                  checked={dettaglioEmail.quietanza_f24}
                  onClick={(e) => handleChangeCheckbox(e, "quietanza_f24")}
                  value={dettaglioEmail.quietanza_f24}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <CustomCheckbox
                  label="dm10"
                  name="dm10"
                  checked={dettaglioEmail.dm10}
                  onClick={(e) => handleChangeCheckbox(e, "dm10")}
                  value={dettaglioEmail.dm10}
                />
              </div>

              <div className="col-span-2 md:col-span-1">
                <CustomCheckbox
                  label="Newsletter"
                  name="newsletter"
                  checked={dettaglioEmail.newsletter}
                  onClick={(e) => handleChangeCheckbox(e, "newsletter")}
                  value={dettaglioEmail.newsletter}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <CustomCheckbox
                  label="PW per dichiarativi criptati"
                  name="pw_dichiarativi_criptati"
                  checked={dettaglioEmail.pw_dichiarativi_criptati}
                  onClick={(e) =>
                    handleChangeCheckbox(e, "pw_dichiarativi_criptati")
                  }
                  value={dettaglioEmail.pw_dichiarativi_criptati}
                />
              </div>
              {dettaglioEmail?.newsletter == 1 &&
                tipologia !== "inserimento" && (
                  <div className="col-span-2 ">
                    <div className="col-span-2 md:col-span-2 h-[40vh]">
                      Liste Newsletter
                      <DataGridPro
                        density="compact"
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: LinearProgress,
                          footer: CustomFooterPersonalized,
                        }}
                        //loading={loading}
                        localeText={
                          itIT.components.MuiDataGrid.defaultProps.localeText
                        }
                        columns={columns}
                        rows={listeAssociate}
                        getRowId={(el) => el.id}
                      />
                    </div>
                  </div>
                )}
            </>
          )}
        </div>

        <Modal
          isOpen={openModal}
          closeButton={() => setOpenModal(false)}
          headerText={`Associa lista`}
          leftButtons={
            <Button
              className="principal-button z-[110]"
              text={tipologia === "elimina" ? "Elimina" : "Associa"}
              type="button"
              icon={<HiOutlineCheck className="button-icon" />}
              onClick={async () => {
                handleAssocia();
              }}
            />
          }
        >
          <div className="col-span-2 md:col-span-1">
            <Select
              type="text"
              label={"Lista"}
              name="tipologia_servizio_altro"
              onChange={(e) => setListaSel(e.target.value)}
              value={listaSel}
              placeholder="Seleziona..."
              options={liste.filter(
                (el) => listeDaRimuove.indexOf(el.id) == -1
              )}
              disabled={false}
            />
          </div>
        </Modal>
      </Modal>
    </>
  );
};

var regex = /^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$/;

const ModalIban = ({ tipologia, cliente, dettaglio, loadIban }) => {
  const [open, setOpen] = useState(false);
  const [iban, setIban] = useState({ iban: "", note: "" });

  const handleChange = (e) => {
    let i = { ...iban };
    i[e.target.name] = e.target.value;
    setIban(i);
  };

  const handleChangeSelect = (e, name) => {
    let i = { ...iban };
    i[name] = e.target.value;
    setIban(i);
  };

  const handleClickOpen = () => {
    if (tipologia === "inserimento") {
      setIban({ iban: "", note: "" });
    } else {
      setIban(dettaglio);
    }

    setOpen(true);
  };

  const handleSave = async () => {
    if (!regex.test(iban.iban) && tipologia !== "elimina") {
      toast.error("Attenzione, inserisci un IBAN valido");
      return;
    }
    try {
      if (tipologia === "inserimento") {
        let savePayroll = await api.post("/iban", {
          iban: iban,
          cliente: cliente,
        });

        toast.success("salvato con successo");
      }

      if (tipologia === "modifica") {
        let savePayroll = await api.put("/iban", { iban: iban });

        toast.success("salvato con successo");
      }

      if (tipologia === "elimina") {
        let savePayroll = await api.delete(`/iban/${iban.ID}`);

        toast.success("eliminato con successo");
      }

      loadIban();
    } catch {
      toast.error("errore durante il salvataggio");
    }
  };

  return (
    <>
      {tipologia === "inserimento" ? (
        <Button
          className="principal-button "
          text="Aggiungi"
          type="button"
          icon={<HiPlus className="button-icon" />}
          onClick={async () => {
            setOpen(true);
          }}
        />
      ) : tipologia === "modifica" ? (
        <Button
          className=" hidden"
          id="editIban"
          type="button"
          icon={<MdEdit className="button-icon" />}
          onClick={async () => {
            handleClickOpen();
          }}
        />
      ) : (
        <Button
          className=" hidden"
          id="deleteIban"
          type="button"
          icon={<MdEdit className="button-icon" />}
          onClick={async () => {
            handleClickOpen();
          }}
        />
      )}
      <Modal
        isOpen={open}
        closeButton={() => setOpen(false)}
        headerText={tipologia + ` Iban`}
        leftButtons={
          <Button
            className="principal-button z-[110]"
            text={tipologia === "elimina" ? "Elimina" : "Salva"}
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSave();
            }}
          />
        }
      >
        <div className="grid grid-cols-2 gap-2">
          {tipologia === "elimina" ? (
            <div className="col-span-2 ">
              Attenzione, sei sicuro di voler eliminare:
              <span className="font-bold">
                {" " + iban.iban + " - " + iban.note}
              </span>
            </div>
          ) : (
            <>
              <div className="col-span-2 md:col-span-1">
                <Input
                  type="text"
                  label={"Iban"}
                  name="iban"
                  onChange={(e) => handleChange(e)}
                  value={iban.iban}
                  maxlength="27"
                  disabled={false}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <Input
                  type="text"
                  label={"Note"}
                  name="note"
                  onChange={(e) => handleChange(e)}
                  value={iban.note}
                  disabled={false}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

/* 
  
  
  */
