import React, { useEffect, useState } from 'react';

export const Button = ({
	text,
	onClick,
	className,
	icon,
	id,
	ref,
	disabled,
}) => {
	return (
		<button
			ref={ref}
			className={className}
			onClick={onClick}
			id={id}
			disabled={disabled}
		>
			{icon}
			{text}
		</button>
	);
};

/* 
<Button
  className= --> "success-button" / "error-button" 
  text={"Conferma"}
  onClick={() => handleDelete()}
  icon={<AiOutlineCheck className="button-icon" />}
/>
*/
