import React, { useState } from 'react';
import Select from 'react-select';

const MultiSelectComponent = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  return (
    <div>
      <h1>Multi-Select Example</h1>
      <Select
        isMulti
        value={selectedOptions}
        onChange={handleChange}
        options={options}
      />
      
    </div>
  );
};

export default MultiSelectComponent;