import { useEffect, useState } from "react";
import { Input } from "../components/Input";
import { CustomCheckbox } from "../components/Checkbox";
import { Select } from "../components/Select";
import { PrincipalButton } from "../components/PrincipalButton";
import { Button } from "../components/Button";
import { HiOutlineCheck, HiOutlineTrash, HiPlus } from "react-icons/hi2";
import LinearProgress from "@mui/material/LinearProgress";
import { CustomFooterPersonalized } from "../components/DataGrid/CustomFooterPersonalized";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import Modal from "../components/Modal";
import api from "../api/api";
import { toast } from "react-toastify";
import { MdEdit, MdCheckCircle } from "react-icons/md";
import logo from "../assets/images/logo-sm.png";
import { HiDownload } from "react-icons/hi";
export const Referenti = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [utenti, setUtenti] = useState([]);
  const [utenteSel, setUtenteSel] = useState(null);
  const [utenteToDelete, setUtenteToDelete] = useState(null);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const columns = [
    {
      field: "Azioni",
      headerName: "Azioni",

      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div className="hidden md:flex flex-nowrap gap-2">
              <Button
                className="edit-button "
                id="editEmail"
                type="button"
                icon={<MdEdit className="button-icon" />}
                onClick={async () => {
                  await setUtenteSel(params.row);

                  document.getElementById("editUtente").click();
                }}
              />
            </div>
            <Button
              className="delete-button"
              type="button"
              icon={<HiOutlineTrash className="button-icon" />}
              onClick={async () => {
                setUtenteToDelete(params.row);

                //await setReferente(params.row);

                //document.getElementById("deleteEmail").click();
              }}
            />{" "}
          </>
        );
      },
    },
    {
      field: "pic",
      filterable: false,
      headerName: "",
      width: 100,
      headerAlign: "center",
      align: "center",
      getApplyQuickFilterFn: undefined, //to remove this column from quick filter
      renderCell: (params) => (
        <img
          alt="user 5"
          src={params.row.pic ? params.row.pic : logo}
          className="relative inline-block h-12 w-12 rounded-full border-2 border-white object-cover object-center hover:z-10 focus:z-10"
        />
      ),
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const loadUtenti = async () => {
    const utenti = await api.get("/utenti");
    setUtenti(utenti.data);
    setTeamLeaders(utenti.data.filter((el) => el.team_leader == 1));
  };

  useEffect(() => {
    loadUtenti();
  }, []);

  const handleExport = async () => {
    try {
      let response = await api.post(
        `/utenti/export`,
        {},
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "" })
      ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "referenti.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.success("download eseguito con successo");
    } catch {
      toast.error("errore nel download dei documenti");
    }
  };

  useEffect(() => {
    if (utenteToDelete) {
      document.getElementById("deleteOrario").click();
    }
  }, [utenteToDelete]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <div className="flex flex-row gap-2">
            <Button
              className="principal-button "
              text="Esporta"
              type="button"
              icon={<HiDownload className="button-icon" />}
              onClick={async () => {
                handleExport();
              }}
            />
            <ModalUtente
              tipologia={"inserimento"}
              teamLeaders={teamLeaders}
              loadUtenti={loadUtenti}
            />
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <ModalUtente
        tipologia={"modifica"}
        utenteSel={utenteSel}
        loadUtenti={loadUtenti}
        teamLeaders={teamLeaders}
      />
      <DeleteUtente utente={utenteToDelete} loadUtenti={loadUtenti} />
      <div className="page-container">
        <div className="w-full md:h-[70vh] h-[70vh] relative el-container">
          <DataGridPro
            density="compact"
            slots={{
              toolbar: CustomToolbar,
              loadingOverlay: LinearProgress,
              footer: CustomFooterPersonalized,
            }}
            //loading={loading}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            rows={utenti}
            getRowId={(r) => r.ID}
          />
        </div>
      </div>
    </>
  );
};

const ModalUtente = ({
  tipologia,
  utenteSel,
  tipologia_referente_esterno,
  cliente,
  dettaglio,
  loadUtenti,
  teamLeaders,
}) => {
  const [open, setOpen] = useState(false);
  const [utente, setUtente] = useState({
    referente: "",
    email_referente: "",
    tipologia: "",
  });
  const [orariDiLavoro, setOrariDiLavoro] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [orarioSel, setOrarioSel] = useState(null);
  const handleChange = (e) => {
    let u = { ...utente };
    u[e.target.name] = e.target.value;
    setUtente(u);
  };

  const handleChangeCheckbox = (e, name) => {
    let u = { ...utente };
    if (name == "team_leader" && e.target.checked) {
      u.responsabile = 29;
    }
    u[name] = e.target.checked ? 1 : 0;
    setUtente(u);
  };

  const handleChangeSelect = (e, name) => {
    let u = { ...utente };
    u[name] = e.target.value;
    setUtente(u);
  };

  const loadOrariDiLavoro = async (utente) => {
    console.log(utente);
    const orari = await api.get(`/orario_lavoro/${utente}`);
    setOrariDiLavoro(orari.data);
  };

  const handleClickOpen = async () => {
    if (tipologia === "modifica") {
      setUtente(utenteSel);
      await loadOrariDiLavoro(utenteSel.ID);
    } else {
      setUtente({ referente: "", email_referente: "", tipologia: "" });
      setOrariDiLavoro([]);
    }

    setOpen(true);
  };

  const handleImage = async (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function () {
      console.log(reader.result);

      let u = { ...utente };
      u.pic = reader.result;
      setUtente(u);

      // document.getElementById("firmaFormatore").src = reader.result;
      /*  let f = { ...pittogramma };
       let foto = reader.result.replace("data:image/jpeg;base64,", "");
       foto = foto.replace("data:image/png;base64,", "");
       f.Immagine = foto; */
      //setPittogramma(f);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleSave = async () => {
    try {
      if (tipologia === "inserimento") {
        let savePayroll = await api.post("/utenti", { user: utente });
        toast.success("inserito con successo");
      } else if (tipologia === "modifica") {
        let updateUser = await api.put("/utenti", { user: utente });

        toast.success("modificato con successo");
      }

      /*  if(tipologia ==="elimina"){
         let savePayroll = await api.delete(`/referenti_aziende_esterno/${dettaglioEmail.ID}`,)
     
         toast.success("eliminato con successo")
       } */

      loadUtenti();
    } catch {
      toast.error("errore durante il salvataggio");
    }
  };

  const columns = [
    {
      field: "Azioni",
      headerName: "Azioni",

      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              className="edit-button "
              type="button"
              icon={<MdEdit className="button-icon" />}
              onClick={async () => {
                await setOrarioSel(params.row);
                document.getElementById("editOrario").click();
              }}
            />
            <Button
              className="delete-button"
              type="button"
              icon={<HiOutlineTrash className="button-icon" />}
              onClick={async () => {
                await setOrarioSel(params.row);
                document.getElementById("deleteOrario").click();
                //await setReferente(params.row);

                //document.getElementById("deleteEmail").click();
              }}
            />{" "}
          </>
        );
      },
    },
    {
      field: "percentuale_orario",
      headerName: "Percentuale orario",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "data_validita",
      headerName: "Data validità",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        moment(params.row.data_validita).format("DD/MM/YYYY"),
    },
  ];

  return (
    <>
      {tipologia === "inserimento" ? (
        <Button
          className="principal-button "
          text="Aggiungi"
          type="button"
          icon={<HiPlus className="button-icon" />}
          onClick={async () => {
            setOpen(true);
          }}
        />
      ) : tipologia === "modifica" ? (
        <Button
          className=" hidden"
          id="editUtente"
          type="button"
          icon={<MdEdit className="button-icon" />}
          onClick={async () => {
            handleClickOpen();
          }}
        />
      ) : (
        <Button
          className=" hidden"
          id="deleteEmail"
          type="button"
          icon={<MdEdit className="button-icon" />}
          onClick={async () => {
            handleClickOpen();
          }}
        />
      )}
      <Modal
        isOpen={open}
        closeButton={() => setOpen(false)}
        headerText={
          tipologia +
          ` Referente` +
          (tipologia === "inserimento" ? "" : " - " + utente?.username)
        }
        leftButtons={
          <Button
            className="principal-button z-[110]"
            text={tipologia === "elimina" ? "Elimina" : "Salva"}
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSave();
            }}
          />
        }
      >
        <DeleteOrarioDiLavoro
          utente={utenteSel}
          tipologia={"Modifica"}
          orarioSel={orarioSel}
          loadOrariDiLavoro={loadOrariDiLavoro}
        />
        <div className="grid grid-cols-4 gap-2">
          <div className="col-span-4 content-center text-center">
            <img
              alt="user 5"
              src={utente?.pic ? utente.pic : logo}
              onClick={() => document.getElementById("fileUploader").click()}
              className="relative inline-block h-48 w-48 rounded-full border-2 border-primary object-cover object-center hover:z-10 focus:z-10 hover:opacity-75 hover:cursor-pointer transition delay-150"
            />
            <input
              type="file"
              id="fileUploader"
              className="hidden"
              accept="image/png"
              onChange={(e) => handleImage(e)}
            />
          </div>

          <div className="col-span-2 md:col-span-1">
            <Input
              type="text"
              label={"nome"}
              name="nome"
              onChange={(e) => handleChange(e)}
              value={utente?.nome}
              disabled={false}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              type="text"
              label={"username"}
              name="username"
              onChange={(e) => handleChange(e)}
              value={utente?.username}
              disabled={false}
            />
          </div>

          <div className="col-span-4 md:col-span-2">
            <Input
              type="text"
              label={"email"}
              name="email"
              onChange={(e) => handleChange(e)}
              value={utente?.email}
              disabled={false}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              type="text"
              label={"Codice Zucchetti"}
              name="codice_zucchetti"
              onChange={(e) => handleChange(e)}
              value={utente?.codice_zucchetti}
              disabled={false}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Input
              type="date"
              label={"Data cessazione"}
              name="data_cessazione"
              onChange={(e) => handleChange(e)}
              value={
                utente?.data_cessazione
                  ? moment(utente.data_cessazione).format("YYYY-MM-DD")
                  : null
              }
              disabled={false}
            />
          </div>
          {/* <div className="col-span-4 md:col-span-2">

            <Select
              type="text"
              label={"Tipologia utente"}
              name="permission"
              onChange={(e) => handleChangeSelect(e, "permission")}
              value={utente?.permission}
              placeholder="Seleziona..."
              options={[{ id: "1", name: "Admin" }, { id: "2", name: "Responsabile" }, { id: "3", name: "Paghe" }, { id: "4", name: "Assunzioni" }]}
              disabled={false}
            />

          </div> */}
          <div className="col-span-4 md:col-span-2">
            <Select
              type="text"
              label={"Team Leader"}
              name="responsabile"
              onChange={(e) => handleChangeSelect(e, "responsabile")}
              value={utente?.responsabile}
              placeholder="Seleziona..."
              options={teamLeaders.map((el) => {
                return { id: el.ID, name: el.nome };
              })}
              disabled={utente.team_leader == 1}
            />
          </div>

          <div className="col-span-4">
            <CustomCheckbox
              label={"Team Leader"}
              name="team_leader"
              onClick={(e) => handleChangeCheckbox(e, "team_leader")}
              value={utente?.team_leader}
            />
            <CustomCheckbox
              label={"Paghe"}
              name="paghe"
              onClick={(e) => handleChangeCheckbox(e, "paghe")}
              value={utente?.paghe}
            />
            <CustomCheckbox
              label={"Assunzioni"}
              name="assunzioni"
              onClick={(e) => handleChangeCheckbox(e, "assunzioni")}
              value={utente?.assunzioni}
            />
            <CustomCheckbox
              label={"Segreteria"}
              name="segreteria"
              onClick={(e) => handleChangeCheckbox(e, "segreteria")}
              value={utente?.segreteria}
            />
            <CustomCheckbox
              label={"F24"}
              name="f24"
              onClick={(e) => handleChangeCheckbox(e, "f24")}
              value={utente?.f24}
            />
            <CustomCheckbox
              label={"Admin"}
              name="admin"
              onClick={(e) => handleChangeCheckbox(e, "admin")}
              value={utente?.admin}
            />
          </div>

          <div className="col-span-3">Orario di lavoro</div>
          <div className="col-span-1">
            <ModalOrarioDiLavoro
              utente={utenteSel}
              tipologia={"inserimento"}
              loadOrariDiLavoro={loadOrariDiLavoro}
            />
            <ModalOrarioDiLavoro
              utente={utenteSel}
              tipologia={"Modifica"}
              orarioSel={orarioSel}
              loadOrariDiLavoro={loadOrariDiLavoro}
            />
          </div>
          <div className="col-span-4 el-container  h-64">
            <DataGridPro
              density="compact"
              slots={{
                //toolbar: CustomToolbar,
                loadingOverlay: LinearProgress,
                footer: CustomFooterPersonalized,
              }}
              //loading={loading}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              columns={columns}
              rows={orariDiLavoro}
              getRowId={(r) => r.ID}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const ModalOrarioDiLavoro = ({
  utente,
  tipologia,
  orarioSel,
  loadOrariDiLavoro,
}) => {
  const [orario, setOrario] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (e) => {
    let o = { ...orario };
    o[e.target.name] = e.target.value;
    setOrario(o);
  };

  const handleOpenModal = () => {
    if (tipologia === "inserimento") {
      setOrario({ percentuale_orario: "", data_validita: null });
    } else {
      console.log(orarioSel.data_validita.toString());
      setOrario(orarioSel);
    }
    setOpenModal(true);
  };

  const handleSave = async () => {
    try {
      if (tipologia === "inserimento") {
        let o = { ...orario };
        o.utente = utente.ID;

        await api.post("/orario_lavoro", { orario: o });
        toast.success("Orario inserito con successo");
      } else {
        await api.put("/orario_lavoro", { orario: orario });

        toast.success("Orario modificato con successo");
      }
      await loadOrariDiLavoro(utente.ID);
    } catch {
      toast.error("Errore durante k");
    }
  };

  return (
    <>
      {tipologia === "inserimento" ? (
        <Button
          className="success-button"
          text="Aggiungi"
          type="button"
          icon={<HiPlus className="button-icon" />}
          onClick={async () => {
            handleOpenModal();
          }}
        />
      ) : (
        <Button
          className="edit-button invisible"
          id="editOrario"
          type="button"
          icon={<MdEdit className="button-icon" />}
          onClick={async () => {
            handleOpenModal();
          }}
        />
      )}

      <Modal
        isOpen={openModal}
        closeButton={() => setOpenModal(false)}
        headerText="Inserisci Orario di lavoro"
        size="small"
        leftButtons={
          <Button
            className="principal-button"
            text="Salva"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSave();
            }}
          />
        }
      >
        <Input
          type="number"
          label={"Percentuale Orario"}
          name="percentuale_orario"
          onChange={(e) => handleChange(e)}
          value={orario?.percentuale_orario}
          disabled={false}
          maxlength={3}
          max={100}
          min={10}
        />
        <Input
          type="date"
          label={"Data di validità"}
          name="data_validita"
          onChange={(e) => handleChange(e)}
          value={
            orario?.data_validita
              ? moment(orario.data_validita).format("YYYY-MM-DD")
              : null
          }
          disabled={false}
        />
      </Modal>
    </>
  );
};

const DeleteOrarioDiLavoro = ({
  utente,
  tipologia,
  orarioSel,
  loadOrariDiLavoro,
}) => {
  const [orario, setOrario] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (e) => {
    let o = { ...orario };
    o[e.target.name] = e.target.value;
    setOrario(o);
  };

  const handleOpenModal = () => {
    console.log(orarioSel);
    setOrario(orarioSel);
    setOpenModal(true);
  };

  const handleSave = async () => {
    try {
      await api.delete(`/orario_lavoro/${orario.ID}`);
      await loadOrariDiLavoro(utente.ID);

      setOpenModal(false);
      toast.success("Orario eliminato con successo");
    } catch (e) {
      console.log(e);
      toast.error("Errore durante la cancellazione dell'orario");
    }
  };

  return (
    <>
      <Button
        className="edit-button invisible"
        id="deleteOrario"
        type="button"
        icon={<MdEdit className="button-icon" />}
        onClick={async () => {
          handleOpenModal();
        }}
      />

      <Modal
        isOpen={openModal}
        closeButton={() => setOpenModal(false)}
        headerText="Inserisci Orario di lavoro"
        size="small"
        leftButtons={
          <Button
            className="principal-button"
            text="Conferma"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSave();
            }}
          />
        }
      >
        Sei sicuro di voler eliminare l'orario di lavoro selezionato?
      </Modal>
    </>
  );
};

const DeleteUtente = ({ utente, loadUtenti }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleSave = async () => {
    try {
      await api.put(`/utenti/disattiva/${utente.ID}`);
      loadUtenti();
      setOpenModal(false);
      toast.success("Utente eliminato con successo");
    } catch (e) {
      console.log(e);
      toast.error("Errore durante la cancellazione dell'orario");
    }
  };
  return (
    <>
      {/*
    
    <Button
              className="delete-button"
              type="button"
              icon={<HiOutlineTrash className="button-icon" />}
              onClick={async () => {
                await setOrarioSel(params.row);
                document.getElementById("deleteOrario").click();
                //await setReferente(params.row);

                //document.getElementById("deleteEmail").click();
              }}
            />{" "}
    */}
      <Button
        className="edit-button invisible"
        id="deleteOrario"
        type="button"
        icon={<MdEdit className="button-icon" />}
        onClick={handleOpenModal}
      />

      <Modal
        isOpen={openModal}
        closeButton={() => setOpenModal(false)}
        headerText="elimina utente"
        size="small"
        leftButtons={
          <Button
            className="principal-button"
            text="Conferma"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSave();
            }}
          />
        }
      >
        Sei sicuro di voler eliminare {utente?.nome} ?
      </Modal>
    </>
  );
};
