import { AiOutlineCheck } from "react-icons/ai";
import Modal from "../components/Modal";
import { Button } from "../components/Button";
import { useState } from "react";
import { MdIncompleteCircle } from "react-icons/md";
import { knowHacces } from "../utils/utils";
import { Memo } from "./Home/Memo";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="page-container grid grid-cols-2 gap-2">
      <div className="el-container col-span-2 md:col-span-1 h-fit">
        <div className="w-full relative flex flex-col  ">
          <div className="flex flex-row justify-start px-3 pt-2">
            <div>
              <h4 className="text-lg font-light text-miller-300 dark:text-slate-100 ">
                Ciao, <span className="font-semibold ">{sessionStorage.getItem("username")}!</span>
              </h4>
            </div>
          </div>
          <div className="mb-auto flex my-4 w-full items-center justify-center">
            <center>
           
            </center>
          </div>
         
        </div>
      </div>




      <div className="el-container col-span-2 md:col-span-1 h-fit">
        <Memo operazione={"inserimento"}/>
      </div>


      <div className="el-container col-span-2 md:col-span-1 h-fit">
        <div className="w-full relative flex flex-col  ">
          <div className="flex flex-row justify-center px-3 pt-2">
            <div>
              <h4 className="text-lg font-semibold text-miller-300 dark:text-slate-100 ">
                titolo: <span className="font-light"> totali</span>
              </h4>
            </div>
          </div>
          <div className="mb-auto flex my-4 w-full items-center justify-center">
            <center>
              <MdIncompleteCircle className="text-6xl text-primary-200 dark:text-miller-500" />
            </center>
          </div>
          <div className="flex flex-row justify-between rounded-2xl px-6 py-3">
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="h-2 w-2 rounded-full bg-primary-200 dark:bg-miller-500"></div>
                <p className="ml-1 text-sm font-normal text-gray-600 dark:text-slate-200">
                  Disponibili
                </p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700 text-miller-300 dark:text-slate-100">
                N
              </p>
            </div>
            <div className="h-11 w-px bg-gray-300 dark:/10"></div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="h-2 w-2 rounded-full bg-primary-200 dark:bg-miller-500"></div>
                <p className="ml-1 text-sm font-normal text-gray-600 dark:text-slate-200">
                  Assegnati
                </p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700 text-miller-300 dark:text-slate-100">
                N
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="el-container col-span-2 md:col-span-1 h-fit">
        <div className="w-full relative flex flex-col  ">
          <div className="flex flex-row justify-center px-3 pt-2">
            <div>
              <h4 className="text-lg font-semibold text-miller-300 dark:text-slate-100 ">
                titolo: <span className="font-light"> totali</span>
              </h4>
            </div>
          </div>
          <div className="mb-auto flex my-4 w-full items-center justify-center">
            <center>
              <MdIncompleteCircle className="text-6xl text-primary-200 dark:text-miller-500" />
            </center>
          </div>
          <div className="flex flex-row justify-between rounded-2xl px-6 py-3">
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="h-2 w-2 rounded-full bg-primary-200 dark:bg-miller-500"></div>
                <p className="ml-1 text-sm font-normal text-gray-600 dark:text-slate-200">
                  Disponibili
                </p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700 text-miller-300 dark:text-slate-100">
                N
              </p>
            </div>
            <div className="h-11 w-px bg-gray-300 dark:/10"></div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="h-2 w-2 rounded-full bg-primary-200 dark:bg-miller-500"></div>
                <p className="ml-1 text-sm font-normal text-gray-600 dark:text-slate-200">
                  Assegnati
                </p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700 text-miller-300 dark:text-slate-100">
                N
              </p>
            </div>
          </div>
        </div>
      </div>



    </div>
  );
};
{
  /* <Modal
        isOpen={isOpen}
        closeButton={() => setIsOpen(false)}
        headerText="Dettaglio attrezzature"
        rightButtons={
          <>
            <Button
              className="success-button"
              size="md"
              text={"Salva"}
              icon={<AiOutlineCheck className="button-icon" />}
              //onClick={handleSave}
            />
          </>
        }
      ></Modal> */
}
