import { useState } from "react";

export const Uniemens = () => {
  const [isOpen, setIsOpen] = useState(true);
  return (
      <div className="page-container">
        <div className=" el-container">
          <h1>Uniemens</h1>
        </div>
      </div>
  );
};
