import React from "react";

export const TabPanel = ({ title, tab, setTab, tabs, content }) => {

  return (
      <div className="mx-auto w-full gap-4">
        <div className="el-container h-fit">
          <div className="w-full flex md:flex-nowrap flex-wrap justify-center mb-2 rounded-md ">
            <span className="text-lg font-semibold text-center">{title}</span>
          </div>
          <div className="relative flex items-end md:justify-around overflow-x-auto md:mx-4">
            {tabs?.map((el) => {
              return (
                <button
                  key={el.id}
                  onClick={() => setTab(el?.id)}
                  className={`w-full md:w-fit px-4 flex flex-row flex-nowrap justify-center items-center gap-1 relative py-2 z-30  ${
                    tab === el?.id
                      ? "border-b-2 border-miller-100 "
                      : ""
                  }`}
                >
                  {el?.icon}
                  <div className="text-sm w-max text-dark">
                    {el?.text}
                  </div>
                </button>
              );
            })}
          </div>
        
        {/* <!-- tabs content --> */}
        <hr className="s border-miller-300  mx-auto md:mx-4" /></div>
        {/* max-w-lg md:max-w-4xl */}
        <div className="flex flex-col">
          
          {content?.map((el) => 
            <div className={"py-4 transition duration-400 ease-in-out " + (el.id===tab?"":"hidden")}>
              {el.child}

            </div>

            )}
          </div>
        </div>
     
  );
};
