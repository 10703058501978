import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { axiosPrivate } from '../api/api';

const RequireAuth = ({ navigate }) => {
	const [verified, setVerified] = useState(false);
	const [loading, setLoading] = useState(true);
	const location = useLocation();

	const verifyCookie = async () => {
		try {
			const response = await axiosPrivate.get('/verifyCookie');
			if (response.status === 200) {
				setVerified(true);
			} else {
				setVerified(false);
			}
		} catch (error) {
			console.log(error);
			setVerified(false);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		verifyCookie();
	}, [location]);
	
	if (loading) {
		return null;
	}
	if (verified) {
		return <Outlet />;
	} else {
		return <Navigate to='/' state={{ from: location }} replace />;
	}
};

export default RequireAuth;
