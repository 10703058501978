import { axiosPrivate } from "../api/api";
import { useEffect} from "react";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
    const accessToken = window.sessionStorage.getItem("accessToken");
    const refresh = useRefreshToken();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            (config) => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    const newAccessToken = await refresh();
                    prevRequest.sent = true;
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [accessToken, refresh]);

    return axiosPrivate;
};

export default useAxiosPrivate;



//if (error?.response?.status === 403 && !prevRequest?.sent && retryCount < 1) {
//setRetryCount(retryCount + 1);

