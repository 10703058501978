
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { createPortal } from "react-dom";
import { Button } from "../../components/Button";
import Modal from "../../components/Modal";
import api from "../../api/api";
import { toast } from "react-toastify";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";
import { HiOutlineCheck } from "react-icons/hi";
import { HiPencil, HiTrash } from "react-icons/hi2";


const colors = ["bg-green-500","bg-orange-500","bg-red-500"]


export const Memo = () => {
    const [open, setOpen] = useState(false);
    const [elenco_memo, setElencoMemo] = useState([]);
    const [memo,setMemo] = useState(null);


    const handleMemo = async () => {

        try {
            
            if(memo.id){
                const resp_insert_memo = await api.put("/memo", { memo: memo })
            }else{
                const resp_insert_memo = await api.post("/memo", { memo: memo })
            }
        

            loadElencoMemo();
            toast.success("Memo inserito con successo")

        } catch {


            toast.error("Errore nell'inserimento del memo")
        }



    }

  
    const handleDeleteMemo = async (id) => {

        try {
      
            const resp_insert_memo = await api.delete(`/memo/${id}`,)

            loadElencoMemo();
            toast.success("Memo eliminato con successo")

        } catch {


            toast.error("Errore nell'inserimento del memo")
        }



    }
    const handleOpen = (operazione,memo) => {

        if(operazione ==="inserimento"){
            setMemo({utente:sessionStorage.getItem("id"),priorita:0,descrizione:""})
        }else{
            setMemo(memo)
        }
       setOpen(true);
    }


    const handleChange = (e) => {

        let m = {...memo};
        m[e.target.name] = e.target.value;
        setMemo(m)

    }
    const handleChangeSelect = (e,name) => {

        let m = {...memo};
        m[name] = e.target.value;
        setMemo(m)
      }


      const loadElencoMemo = async() => {

        try {
            const risp = await api.get(`/memo/${sessionStorage.getItem("id")}`);
            setElencoMemo(risp.data)
            console.log(risp.data)
          } catch {
            toast.error("Errore durante il caricamento")
          }



      }

      useEffect(() => {

        loadElencoMemo();
    
      }, []);




    return (

        <>


            <div className="w-full relative flex flex-col  ">

                <div className="grid grid-cols-2">
                    <div>
                        <h4 className="text-lg font-semibold text-miller-300 dark:text-slate-100 ">
                            Memo
                        </h4>
                    </div>
                    <div className="justify-self-end">
                        <MdAdd onClick={() => handleOpen("inserimento")} className="hover:cursor-pointer text-miller-300 transition duration-300	 hover:text-white hover:bg-miller-200 h-6 w-6 transition-delay-500 rounded-full" />

                    </div>

                </div>
                <div className="mb-auto flex my-4 w-full items-center justify-center">

                </div>
                {elenco_memo.length > 0 ?
                   <div className="bg-miller-100 rounded-md h-32 max-h-32 overflow-y-auto">
                    <div className="flex flex-col overflow-y-auto w-full p-2">
                   {elenco_memo.map(el => 
                       <div key={el.id} className="w-full bg-white rounded-md shadow-md  mb-2 flex flex-row items-center justify-between p-2">
                            <div className="flex flex-row p-2">
                                <HiPencil onClick={()=>handleOpen("modifica",el)} className="hover:cursor-pointer text-miller-300 transition duration-300	 hover:text-white hover:bg-miller-200 h-6 w-6 transition-delay-500 rounded-full"/>
                                <HiTrash onClick={()=>handleDeleteMemo(el.id)} className="hover:cursor-pointer text-red-500 transition duration-300	 hover:text-red-200 hover:bg-red-500 h-6 w-6 transition-delay-500 rounded-full"/>
                            </div>
                            <div className="text-sm">
                                {el.descrizione}
                            </div>   
                            <div className="">
                               <div className={"h-2 p-2 rounded-full "+colors[el.priorita]  }>

                               </div>
                            </div>   
                       </div>
                   )}
                   </div>
               </div>:
                    <div className="flex flex-row justify-center px-6 py-3 text-miller-300 text-center">
                        Non sono presenti memo, aggiungine uno per tenere traccia di tutte le tue attività!
                    </div>
                }
            </div>
            {open && createPortal(
                <Modal isOpen={open} closeButton={() => setOpen(false)} headerText={"MEMO"}
                
                leftButtons={
                    <Button
                        className="principal-button z-[110]"
                        text={"Salva"}
                        type="button"
                        icon={<HiOutlineCheck className="button-icon" />}
                        onClick={handleMemo}
                    /> 

                }
                >

                    <div className="grid w-full">
                        <div>
                            <Input
                                type="text"
                                label={"Descrizione"}
                                name="descrizione"
                                onChange={(e) => handleChange(e)}
                                value={memo?.descrizione}

                            />

                        </div>
                        <div>

                            <Select
                                type="text"
                                label={"Priorità"}
                                name="priorita"
                                onChange={(e) => handleChangeSelect(e,"priorita")}
                                value={memo?.priorita}
                                placeholder="SELEZIONA..."
                                options={[
                                    { id: 0, name: "Bassa" },
                                    { id: 1, name: "Media" },
                                    { id: 2, name: "Alta" },
                                ]}
                            
                            />

                        </div>

                    </div>



                </Modal>,
                document.getElementById("portalModal")
            )}
        </>
    );
};