import React,{useEffect, useState} from "react";
import loginImg from "../assets/images/office.jpg";
import { ToastContainer, toast } from "react-toastify";
import api from "../api/api";
import { useNavigate } from "react-router-dom";
export const SetPassword = () =>{

    const [show, setShow] = useState(false);
    const [user_id, set_user_id] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const navigate = useNavigate();

    const handleConfirm = async(e) =>{
        e.preventDefault();
        if(password !==passwordConfirm && password !== ""){
            toast.error("Attenzione le password non corrispondono")
            return
        }else{
          
            let updateUser= await api.put("/utenti/password",{psw:password,id:user_id})
            navigate("/");
            
        }


    }


const checkToken = async() =>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token')

    const req = await api.post("/utenti/checkToken",{token:token})
    set_user_id(req.data)

}



useEffect(()=>{

 checkToken();

},[])




return(
    
    
    <div className="flex w-full h-screen flex-nowrap  relative">
        		<ToastContainer />
        <div className="absolute top-5 right-5">
        </div>
        {/* Container img */}
        <div
          className="lg:flex hidden justify-center align-middle bg-cover bg-center w-2/3 h-[calc(100vh - 4rem)] pl-2 bg-white dark:bg-miller-300/90"
          style={{ backgroundImage: `url(${loginImg})` }}
        ></div>
        {/* Container */}
        <div className="flex justify-center align-middle flex-wrap w-full lg:w-1/3 bg-slate-100 dark:bg-miller-300">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto w-full md:h-screen lg:py-0 ">
            <div className="w-full md:mt-0 sm:max-w-md lg:-ml-52 px-8 lg:px-6 py-12 bg-miller-200 dark:bg-miller-500 border-none border-miller-300 dark:border-miller-300 rounded-md relative">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-1">
                <form
                 onSubmit={handleConfirm}
                  className="space-y-4 md:space-y-6 "
                >
                  {/* <!-- text login --> */}
                  <div
                    //onClick={() => setOpen(!open)}
                    className={` absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-4 py-4 rounded-full  dark:bg-miller-300 bg-slate-100 z-50 duration-500 `} //hover:rotate-180
                  >	{sessionStorage.getItem("pic")?    <img
                 
                  src={sessionStorage.getItem("pic")}
                  className="h-24 w-24 dark:text-slate-200 text-miller-300 rounded-full"
              />:
                    <img
                      id="logoSidebarMbl"
                      src={
                        localStorage.getItem("theme") === "light"
                          ? require("../assets/images/logo-sm.png")
                          : require("../assets/images/logo-chiaro-sm.png")
                      }
                      //src={require("../assets/images/logo-sm.png")}
                      className={`w-24 rounded-full mx-auto `}
                    />}
                  </div>
                  <div>
                    <h1 className="text-center text-2xl font-bold text-slate-200 dark:text-slate-200 mb-6 z-10">
                      CRM Paghe - imposta password
                    </h1>
                    {sessionStorage.getItem("pic")?<p className="text-center text-slate-200" >Ciao USER</p>:<></>}
                  </div>
                  {/* <!-- user input --> */}

                  
                  {/* <!-- password input --> */}
                 <p className="text-white -mb-30"> Password</p>
                  <div className="relative">
                    <span className="absolute right-0 flex items-center pr-2 h-full">
                      <button
                        type="button"
                        onClick={() => setShow(!show)}
                        className="p-1 focus:outline-none focus:shadow-outline"
                      >
                        <svg
                          hidden={!show}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                          <path
                            fillRule="evenodd"
                            d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <svg
                          hidden={show}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                          <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                          <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                        </svg>
                      </button>
                    </span>
                    <input
                      type={show ? "text" : "password"}
                      name="password"
                      id="password"
                      className="w-full py-4 px-8 rounded-md bg-white ring-1 dark:ring-miller-300"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                  <p className="text-white">        Conferma password</p>
          
                  <div className="relative">
                    <span className="absolute right-0 flex items-center pr-2 h-full">
                      <button
                        type="button"
                        onClick={() => setShowConfirm(!showConfirm)}
                        className="p-1 focus:outline-none focus:shadow-outline"
                      >
                        <svg
                          hidden={!showConfirm}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                          <path
                            fillRule="evenodd"
                            d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <svg
                          hidden={showConfirm}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                          <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                          <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                        </svg>
                      </button>
                    </span>
                
                    <input
                      type={showConfirm ? "text" : "password"}
                      name="password"
                      id="password"
                      className="w-full py-4 px-8 rounded-md bg-white ring-1 dark:ring-miller-300"
                      placeholder="Password"
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      value={passwordConfirm}
                    />
                  </div>
                  {/* <!-- button --> */}
                  <button
                    type="submit"
                    className="w-full transition-all font-semibold rounded-md text-lg px-5 py-3 text-center hover:bg-transparent border-white dark:border-slate-200 border dark:text-slate-200  dark:bg-miller-300 bg-white text-miller-200 hover:text-slate-200 dark:hover:bg-miller-500 hover:scale-95"
                  >
                    Crea Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>)

}


